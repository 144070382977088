import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { createApp } from 'vue';
// import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import { log } from './helpers/logger';
import * as Sentry from '@sentry/vue';

const firebaseConfig = {
  apiKey: 'AIzaSyBVChFB122l5qsDHv6xNNFjsZt4hzFB-yY',
  authDomain: 'bftz-cetli.firebaseapp.com',
  databaseURL: 'https://bftz-cetli.firebaseio.com',
  projectId: 'bftz-cetli',
  storageBucket: 'bftz-cetli.appspot.com',
  messagingSenderId: '62790492039',
  appId: '1:62790492039:web:e190ef8d061a684fcdfd22',
  measurementId: 'G-R20J2998M1'
};

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp, 'europe-west3');

// const pinia = createPinia();

let isAppCreated = false;
onAuthStateChanged(auth, async user => {
  if (!isAppCreated) {
    isAppCreated = true;
    // createApp({
    //   extends: App,
    //   beforeCreate() { store.dispatch('core/setInit', false); }
    // })
    const app = createApp(App);

    Sentry.init({
      app,
      dsn: 'https://f5835a652ded80605d6e24fd82bbd143@o4508127056232448.ingest.de.sentry.io/4508127145754704',
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https://cetli.bftz.hu/', 'https://cetli-teszt.bftz.hu'],
      // Session Replay
      // This sets the sample rate at 10%.
      // You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysSessionSampleRate: 0.1,
      // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      replaysOnErrorSampleRate: 1.0,
    });

    app.use(store);
    // app.use(pinia);
    app.use(router);
    app.use(vuetify);
    app.use(i18n);
    app.mount('#app');

    store.dispatch('core/setInit', false);
    // if the app loads and the user is already logged in we want to log
    if (user?.uid) {
      await log({ type: 'refresh', userId: user.uid, email: user.email ?? '' });
    }
  }

  // after login auth state will change and we want to store the user
  if (user?.uid) {
    store.dispatch('user/autoSignin', user, { root: true });
  }
});

export {
  firebaseApp,
  db,
  auth,
  functions,
};
