import { MutationTree } from 'vuex';
import { SchoolDistrictState } from './types';
import { SchoolDistrict } from 'types';

const addSchoolDistricts = (state: SchoolDistrictState, payload: SchoolDistrict[]) => {
  state.schoolDistricts = payload;
};

const setSchoolDistrictsLoading = (state: SchoolDistrictState, payload: boolean) => {
  state.schoolDistrictsLoading = payload;
};

const mutations: MutationTree<SchoolDistrictState> = {
  addSchoolDistricts,
  setSchoolDistrictsLoading
};

export default mutations;
