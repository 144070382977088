<template>
  <v-dialog persistent scrollable :model-value="visible" max-width="500">
    <v-form v-model="formValid" ref="form">
      <v-card v-if="!deleteEntry">
        <v-card-title>{{ form.id ? $t('edit') : $t('add-new') }}</v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-text-field
                color="blue-darken-2"
                :label="$t('naming')"
                required
                :rules="[rules.required]"
                v-model="form.data.name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                color="blue-darken-2"
                :label="$t('zip')"
                required
                :rules="[rules.required]"
                v-mask="mask.zip"
                v-model="form.data.zip"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field
                color="blue-darken-2"
                :label="$t('settlement')"
                required
                :rules="[rules.required]"
                v-model="form.data.city"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                color="blue-darken-2"
                :label="$t('address')"
                required
                :rules="[rules.required]"
                v-model="form.data.address"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12">
              <v-switch color="blue-darken-2" v-model="form.data.isSchool" :label="$t('school') + '?'"></v-switch>
            </v-col>
          </v-row>
          <v-row v-if="form.data.isSchool">
            <v-col cols="12">
              <v-autocomplete
                color="blue-darken-2"
                v-model="form.data.schoolDistrictId"
                :items="schoolDistricts"
                item-title="name"
                item-value="id"
                :label="$t('school-district')"
              ></v-autocomplete>
            </v-col>
          </v-row> -->

          <v-list class="pa-0 mt-10">
            <v-list-item class="pa-0">
              <v-list-item-title>{{ $t('contact-person') }}</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-row>
            <v-col cols="12">
              <v-text-field color="blue-darken-2" :label="$t('name')" v-model="form.data.contactName"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                color="blue-darken-2"
                :label="$t('telephone')"
                v-mask="mask.telephone"
                v-model="form.data.contactTelephone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                color="blue-darken-2"
                :label="$t('email')"
                :rules="[rules.email]"
                v-model="form.data.contactEmail"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field color="blue-darken-2" :label="$t('website')" v-model="form.data.url"></v-text-field>
            </v-col>
          </v-row>

          <v-list class="pa-0 mt-10">
            <v-list-item class="pa-0">
              <v-list-item-title>{{ $t('note') }}</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-row>
            <v-col cols="12">
              <v-textarea color="blue-darken-2" auto-grow rows="1" row-height="24" v-model="form.data.note"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red-darken-2" variant="text" v-if="form.id" @click="deleteEntry = true">{{ $t('delete') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="$emit('close')">{{ $t('cancel') }}</v-btn>
          <v-btn color="blue-darken-2" variant="text" :loading="loading" @click="save()">{{ $t('save') }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="deleteEntry">
        <v-card-text class="confirm-text">{{ $t('confirm-to-delete') }}</v-card-text>
        <v-card-actions>
          <v-btn color="red-darken-2" :loading="deleting" variant="text" @click="remove()">{{ $t('delete') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-2" variant="text" @click="deleteEntry = false">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { addDoc, collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '@/main';
import { rules } from '../../helpers/validator-rules';
import { mask } from 'vue-the-mask';
export default {
  name: 'AdressDialog',
  props: ['visible', 'id'],
  emits: ['close'],
  directives: {
    mask,
  },
  data() {
    return {
      loading: false,
      deleting: false,
      form: {
        id: null,
        data: {
          name: null,
          zip: null,
          city: null,
          address: null,
          note: null,
          contactName: null,
          contactEmail: null,
          contactTelephone: null,
          isSchool: false,
          schoolDistrictId: null,
          deleted: false,
          url: null,
        },
      },
      formValid: false,
      deleteEntry: false,
      mask: {
        telephone: '+36 ## ### ####',
        zip: '####',
      },
      rules,
    };
  },
  computed: {
    address() {
      return this.id && this.$store.getters['address/addressById'](this.id);
    },
    schoolDistricts() {
      return this.$store.getters['schoolDistrict/schoolDistricts'];
    },
  },
  methods: {
    async save() {
      if (this.formValid) {
        this.loading = true;
        if (this.form.id) {
          const collectionRef = collection(db, 'addresses');
          const docRef = doc(collectionRef, this.form.id);
          try {
            const payload = {
              ...this.form.data,
              updateTime: new Date().toISOString(),
            };
            if (payload.schoolDistrict) {
              payload.schoolDistrict = this.schoolDistrictById(this.form.data.schoolDistrictId);
            }
            await setDoc(docRef, payload);
            this.showSnackbar('Sikeres művelet');
            this.$emit('close');
          } catch (error) {
            this.showSnackbar(error.message);
          } finally {
            this.loading = false;
          }
        } else {
          const collectionRef = collection(db, 'addresses');
          try {
            const payload = {
              ...this.form.data,
              createTime: new Date().toISOString(),
              updateTime: new Date().toISOString()
            };
            if (payload.schoolDistrict) {
              payload.schoolDistrict = this.schoolDistrictById(this.form.data.schoolDistrictId);
            }
            await addDoc(collectionRef, payload);
            this.showSnackbar('Sikeres művelet');
            this.$emit('close');
          } catch (error) {
            this.showSnackbar(error.message);
          } finally {
            this.loading = false;
          }
        }
      } else {
        this.$refs.form.validate();
      }
    },
    async remove() {
      this.deleting = true;
      const collectionRef = collection(db, 'addresses');
      const docRef = doc(collectionRef, this.form.id);
      try {
        await updateDoc(docRef, {
          deleted: true,
          updateTime: new Date().toISOString(),
        });
        this.showSnackbar('Sikeres művelet');
        this.$emit('close');
      } catch (error) {
        this.showSnackbar(error.message);
      } finally {
        this.deleting = false;
      }
    },
    showSnackbar(text) {
      this.$store.dispatch('core/setSnackbar', { text, duration: 3000 });
    },
    schoolDistrictById(id) {
      return this.schoolDistricts && this.schoolDistricts.find(item => item.id === id);
    },
  },
  created() {
    if (this.id) {
      const { id, ...data } = this.address;
      this.form.id = id;
      this.form.data = JSON.parse(JSON.stringify(data));
    }
  },
};
</script>
