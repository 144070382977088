import { createStore } from 'vuex';

import address from './modules/address';
import core from './modules/core';
import event from './modules/event';
import eventGroup from './modules/eventGroup';
import eventType from './modules/eventType';
import instrument from './modules/instrument';
import role from './modules/role';
import schoolDistrict from './modules/schoolDistrict';
import user from './modules/user';

const store = createStore({
  modules: {
    address,
    core,
    event,
    eventGroup,
    eventType,
    instrument,
    role,
    schoolDistrict,
    user
  }
});

export default store;
