import { ActionContext, ActionTree } from 'vuex';
import { collection, orderBy, query, QuerySnapshot, where } from 'firebase/firestore';
import { listener } from '@/services/firebase';
import { EventTypeState } from './types';
import { RootState } from '@/store/types';
import { db } from '@/main';
import { readAllFromIndexedDB, writeToIndexedDB } from '@/services/indexedDB';

let unsubscribeVar: any;

const fetchEventTypes = async (context: ActionContext<EventTypeState, RootState>): Promise<void> => {
  context.commit('setEventTypesLoading', true);

  const { data: initialData, lastUpdateTime } = await readAllFromIndexedDB('eventTypes');
  context.commit('addEventTypes', initialData);

  const collectionRef = collection(db, 'eventTypes');

  const callback = async (snapshot: QuerySnapshot) => {
    console.log('snap/evt', snapshot.docs.length);
    if (snapshot.docs.length === 0) {
      context.commit('setEventTypesLoading', false);
      return;
    }

    snapshot.docs.forEach(async doc => {
      await writeToIndexedDB('eventTypes', { id: doc.id, ...doc.data() });
    });
    const { data, lastUpdateTime } = await readAllFromIndexedDB('eventTypes');
    context.commit('addEventTypes', data);
    context.commit('setEventTypesLoading', false);
    unsubscribeVar();
    unsubscribeVar = listener(query(collectionRef, where('updateTime', '>', lastUpdateTime), orderBy('updateTime')), callback);
  };

  unsubscribeVar = listener(
    lastUpdateTime
      ? query(collectionRef, where('updateTime', '>', lastUpdateTime), orderBy('updateTime'))
      : query(collectionRef, orderBy('updateTime')),
    callback
  );
};

const unsubscribe = () => {
  unsubscribeVar();
};

const actions: ActionTree<EventTypeState, RootState> = {
  fetchEventTypes,
  unsubscribe,
};

export default actions;
