<template>
  <a :href="href" target="_blank" v-if="props?.address?.city && props?.address?.address">{{ $t('display-on-map') }}</a>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps(['address']);
const href = computed(() => {
  return `https://www.google.com/maps/dir//${props?.address?.zip ?? ''} ${props?.address?.city}, ${props?.address?.address}`;
});
</script>
