<template>
  <div>
    <v-app-bar flat color="blue-darken-3">
      <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
      <v-toolbar-title class="hidden-sm-and-down ml-0 px-4">{{ $t('contacts') }}</v-toolbar-title>
      <v-spacer />
      <v-text-field
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('search')"
        clearable
        v-model="searchTerm"
        @keyup="setSearchTerm()"
        @click:clear="clearSearchTerm()"
      />
      <v-spacer />
      <v-btn icon @click.stop="dialogVisible = true" v-if="userCanEdit">
        <v-icon icon="mdi-plus" />
      </v-btn>
    </v-app-bar>

    <v-container>
      <v-skeleton-loader type="list-item-two-line@10" v-if="loadingState" />
      <template v-else>
        <v-pagination
          v-if="totalPages > 1"
          class="my-6"
          v-model="page"
          :length="totalPages"
          :total-visible="10"
        />

        <div class="mb-4">Találatok száma: {{ usersCount }}</div>

        <v-expansion-panels v-if="users.length > 0" v-model="panel">
          <v-expansion-panel v-for="(item, i) in users" :key="i">
            <v-expansion-panel-title class="expansion-panel-header">
              <v-list-item class="list-item">
                <v-list-item-title>
                  <v-row>
                    <v-col cols="12" md="6">{{ fullName(item.lastname, item.firstname) }}</v-col>
                    <v-col cols="12" md="6" class="text-grey roles justify-md-end pt-0 pt-sm-3">
                      <span class="role" v-for="roleId in item.userRoles" v-bind:key="roleId">
                        {{ getUserRoleById(roleId)?.name }}
                      </span>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-list density="compact">
                <v-list-item class="px-0" v-if="item.userRoles?.includes('musician')">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-trumpet"></v-icon>
                  </template>
                  <div>
                    <v-chip class="instrument" label v-for="instrumentId in item.instruments" v-bind:key="instrumentId">
                      {{ getInstrumentById(instrumentId)?.name }}
                    </v-chip>
                  </div>
                </v-list-item>
                <v-list-item class="px-0">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-phone"></v-icon>
                  </template>
                  <a :href="'tel:' + item.telephone">{{ item.telephone }}</a>
                </v-list-item>
                <v-list-item class="px-0">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-email"></v-icon>
                  </template>
                  <a :href="'mailto:' + item.email">{{ item.email }}</a>
                </v-list-item>
              </v-list>

              <v-divider class="my-4" v-if="userCanEdit" />

              <v-row v-if="userCanEdit">
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pa-0">
                  <v-btn color="blue-darken-2" variant="text" @click="edit(item.id)">{{ $t('edit') }}</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-list v-else>
          <v-list-item disabled>
            <v-list-item-title class="list-item-title">{{ $t('no-results') }}</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-pagination
          v-if="totalPages > 1"
          class="my-6"
          v-model="page"
          :length="totalPages"
          :total-visible="10"
        />
      </template>
    </v-container>

    <v-user-dialog
      v-if="dialogVisible"
      v-bind:visible="dialogVisible"
      v-bind:id="id"
      v-on:close="closeDialog()"
    ></v-user-dialog>
  </div>
</template>
<script>
import UserDialog from '@/components/dialog/UserDialog.vue';
export default {
  name: 'UsersView',
  components: {
    'v-user-dialog': UserDialog,
  },
  data: () => ({
    dialogVisible: false,
    id: null,
    page: 1,
    panel: [],
    searchTerm: null,
  }),
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
    userCanEdit() {
      return this.user && ['admin', 'editor'].some(role => this.user?.userRoles?.includes(role));
    },
    users() {
      return this.$store.getters['user/usersSubset'](this.page);
    },
    usersCount() {
      return this.$store.getters['user/usersCount'];
    },
    totalPages() {
      if (this.usersCount > 0) {
        return Math.ceil(this.usersCount / 10);
      } else {
        return 0;
      }
    },
    userRoles() {
      return this.$store.getters['user/userRoles'];
    },
    instruments() {
      return this.$store.getters['instrument/instruments'];
    },
    loadingState() {
      return this.$store.getters.loadingState;
    },
  },
  watch: {
    page(value) {
      if (value) {
        this.panel = [];
      }
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch('core/toggleDrawer', null);
    },
    edit(id) {
      this.id = id;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.id = null;
      this.dialogVisible = false;
    },
    setSearchTerm() {
      this.page = 1;
      this.$store.dispatch('user/setUserSearchTerm', this.searchTerm, { root: true });
    },
    clearSearchTerm() {
      this.page = 1;
      this.$store.dispatch('user/setUserSearchTerm', null, { root: true });
    },
    fullName(lastname, firstname) {
      return lastname + ' ' + firstname;
    },
    getUserRoleById(id) {
      return this.userRoles?.find(role => role.id === id);
    },
    getInstrumentById(id) {
      return this.instruments?.find(instrument => instrument.id === id);
    },
  },
  beforeUnmount() {
    this.clearSearchTerm();
  },
};
</script>
<style scoped>
.confirm-text {
  font-size: 1rem;
}
.expansion-panel-header {
  padding: 6px 18px 6px 6px;
}
.roles {
  display: flex;
  flex-direction: row;
}
.role:not(:last-child)::after {
  content: ', ';
  white-space: pre;
}
.instrument:not(:last-child) {
  margin-right: 0.5rem;
}
</style>
