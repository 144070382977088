<template>
  <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" :model-value="visible">
    <v-card>
      <v-toolbar color="blue-darken-3">
        <v-btn icon="mdi-close" @click="close()" />
        <v-toolbar-title v-if="copying">{{ $t('copy-event') }}</v-toolbar-title>
        <v-toolbar-title v-else-if="form.id">{{ $t('edit') }}</v-toolbar-title>
        <v-toolbar-title v-else>{{ $t('new-event') }}</v-toolbar-title> <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn variant="text" :loading="loading" @click="save()">{{ $t('save') }}</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-form v-model="formValid" ref="form">
        <v-container fluid class="pa-4">
          <v-row justify="space-between" class="mt-2">
            <v-col cols="12" md="4" lg="4">
              <v-row>
                <!-- date -->
                <v-col cols="12" md="4">
                  <v-text-field :model-value="form.data.date" :label="$t('date')" persistent-hint disabled></v-text-field>
                </v-col>

                <!-- time -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-mask="mask.time"
                    :label="$t('start-time')"
                    required
                    :rules="[rules.required]"
                    v-model="form.data.startTime"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-mask="mask.time"
                    :label="$t('end-time')"
                    required
                    :rules="[rules.required]"
                    v-model="form.data.endTime"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- event type -->
              <v-row>
                <v-col cols="12">
                  <v-radio-group v-model="form.data.eventType" inline required :rules="[rules.required]" :label="$t('event-type')">
                    <v-radio :label="t.shortName" :value="t.id" v-for="t in eventTypes" :key="t.id" />
                  </v-radio-group>
                </v-col>
              </v-row>

              <!-- address -->
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :label="$t('place')"
                    v-model="form.data.addressId"
                    :items="addresses"
                    item-title="name"
                    item-value="id"
                    auto-select-first
                    clearable
                    required
                    :rules="[rules.required]"
                  >
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                        v-bind="props"
                        :title="item.raw.name"
                      >
                        <v-list-item-subtitle><AddressText :address="item.raw" /></v-list-item-subtitle>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <!-- note -->
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    auto-grow
                    rows="1"
                    row-height="24"
                    :label="$t('note')"
                    v-model="form.data.note"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>

            <!-- participants -->
            <v-col cols="12" md="4" lg="3">
              <ParticipantForm v-model:participants="form.data.participants" />
            </v-col>

            <v-col cols="12" md="7" lg="4"></v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { db } from '@/main';
import { userById } from '@/helpers/form.helper';
import { addTime, isValidTime } from '@/helpers/datetime';
import { rules } from '../../helpers/validator-rules';
import { mask } from 'vue-the-mask';
import ParticipantForm from '../list/ParticipantForm.vue';
import AddressText from '@/components/AddressText.vue';

export default {
  name: 'EventDialog',
  props: ['visible', 'id', 'copying', 'date'],
  directives: { mask },
  components: {
    ParticipantForm,
    AddressText,
  },
  data() {
    return {
      loading: false,
      searchTerm: '',
      form: {
        id: null,
        data: {
          deleted: false,
          date: null,
          startTime: null,
          endTime: null,
          addressId: null,
          participants: [],
          distance: null,
          note: null,
          eventType: null,
        },
      },
      formValid: false,
      addPerformerDialog: false,
      removeParticipantDialog: false,
      participantId: null,
      mask: {
        date: '####-##-##',
        time: '##:##',
      },
      rules,
    };
  },
  computed: {
    event() {
      return this.id && this.$store.getters['event/eventById'](this.id);
    },
    eventTypes() {
      return this.$store.getters['eventType/eventTypes'];
    },
    addresses() {
      return this.$store.getters['address/addresses'];
    },
    users() {
      return this.$store.getters['user/filteredUsersBySearchTerm'];
    },
    instruments() {
      return this.$store.getters['instrument/instruments'];
    },
  },
  watch: {
    'form.data.startTime': function (time) {
      if (isValidTime(time) && !this.form.data.endTime) {
        this.form.data.endTime = addTime(this.form.data.startTime, 45);
      }
    },
  },
  methods: {
    async save() {
      if (this.formValid) {
        this.loading = true;
        if (this.copying) {
          const collectionRef = collection(db, 'events');
          try {
            await addDoc(collectionRef, { ...this.form.data, createTime: new Date().toISOString(), updateTime: new Date().toISOString() });
            this.close();
          } catch (error) {
            this.showSnackbar(error);
          } finally {
            this.loading = false;
          }
        } else if (this.form.id) {
          const collectionRef = collection(db, 'events');
          const docRef = doc(collectionRef, this.form.id);
          try {
            await setDoc(docRef, { ...this.form.data, updateTime: new Date().toISOString() });
            this.close();
          } catch (error) {
            this.showSnackbar(error);
          } finally {
            this.loading = false;
          }
        } else {
          const collectionRef = collection(db, 'events');
          try {
            await addDoc(collectionRef, { ...this.form.data, createTime: new Date().toISOString(), updateTime: new Date().toISOString() });
            this.close();
          } catch (error) {
            this.showSnackbar(error);
          } finally {
            this.loading = false;
          }
        }
      } else {
        this.$refs.form.validate();
      }
    },
    close() {
      this.$emit('close');
    },
    userById(id) {
      return userById(this.users, id);
    },
    addPerformer(form) {
      this.form.data.participants.push({ ...form, confirmed: false });
      this.addPerformerDialog = false;
    },
    showRemoveParticipantDialog(id) {
      this.userId = id;
      this.removeParticipantDialog = true;
    },
    removeParticipant() {
      const index = this.form.data.participants.findIndex(p => p.userId === this.participantId);
      this.form.data.participants.splice(index, 1);
      this.removeParticipantDialog = false;
    },
    showSnackbar(text) {
      this.$store.dispatch('core/setSnackbar', { text });
    },
    addTime(time, durationInMinutes) {
      return addTime(time, durationInMinutes);
    },
    isValidTime(time) {
      return isValidTime(time);
    },
  },
  created() {
    if (this.id) {
      const { id, ...data } = this.event;
      this.form.id = id;
      this.form.data = JSON.parse(JSON.stringify(data));
      // this.form.data.eventType = String(this.form.data.eventType);
    }
    if (this.date) {
      this.form.data.date = this.date;
    }
  },
};
</script>
<style scoped>
.confirm-text {
  font-size: 1rem;
}
</style>
