<template>
  <v-app>
    <v-app-bar color="transparent" dense flat>
      <v-btn icon @click="router.push({ name: 'login' })">
        <v-icon icon="mdi-arrow-left" />
      </v-btn>
      <v-toolbar-title>
        <span>{{ $t('forgotten-password') }}</span>
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>

    <v-container class="fill-height" fluid>
      <v-row class="fill-height mb-n6" align="center" justify="center" no-gutters>
        <v-col cols="12" sm="8" md="4">
          <v-card
            class="mx-auto mb-2"
            max-width="414"
            :elevation="$vuetify.display.smAndUp ? 1 : 0"
            v-if="!resetRequested"
          >
            <v-card-text class="pa-0" :class="{ 'pa-4': $vuetify.display.smAndUp }">
              <v-form v-model="formValid" ref="formRef">
                <v-text-field
                  :label="$t('email-address')"
                  name="email"
                  type="text"
                  v-model="form.email"
                  required
                  :rules="[rules.required, rules.email]"
                  validate-on="blur"
                  class="mb-6"
                  v-on:keyup.enter="false"
                />
              </v-form>
            </v-card-text>
            <v-card-actions class="px-4">
              <v-spacer />
              <v-btn variant="flat" v-if="!resetRequested" v-on:click="reset" v-bind:loading="loading">{{
                $t('next')
              }}</v-btn>
            </v-card-actions>
          </v-card>
          <v-card class="text-center mx-auto mt-4" max-width="414" elevation="0" v-if="resetRequested">
            <span>{{ $t('reset-requested') }}</span>
            <br /><br />
            <v-btn variant="outlined" color="primary" @click="router.push({ name: 'login' })">{{ $t('login') }}</v-btn>
          </v-card>
          <v-card class="text-error text-center mx-auto mt-4" max-width="414" elevation="0" v-if="errorCode">
            <span>{{ $t('error.' + errorCode) }}</span>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script setup>
import { reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { rules } from '@/helpers/validator-rules';

const store = useStore();
const router = useRouter();

const formRef = ref();
const form = reactive({ email: '' });
const resetRequested = ref(false);
const errorCode = ref(null);
const loading = ref(false);

const reset = async () => {
  const { valid: isFormValid } = await formRef.value.validate();
  if (isFormValid) {
    try {
      resetRequested.value = false;
      errorCode.value = null;
      loading.value = true;
      await store.dispatch('user/resetPassword', { email: form.email }, { root: true });
      resetRequested.value = true;
    } catch (error) {
      errorCode.value = error.code;
    }
    finally {
      loading.value = false;
    }
  }
};
</script>
