import { createRouter, createWebHistory, RouteLocationNormalizedGeneric } from 'vue-router';
import { auth } from '../main';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import Profile from '../views/ProfileView.vue';
import UsersView from '../views/UsersView.vue';
import InstrumentsView from '../views/InstrumentsView.vue';
import SchoolDistrictsView from '../views/SchoolDistrictsView.vue';
import Addresses from '../views/AddressesView.vue';
import StatView from '../views/StatView.vue';
import PageNotFoundView from '../views/PageNotFoundView.vue';
import NoRightsView from '../views/NoRightsView.vue';
import ForgotPasswordView from '../views/ForgotPasswordView.vue';
import EventTypeView from '../views/EventTypeView.vue';

interface RouteMeta {
  title: string;
  requiresAuth?: boolean;
  permission?: string[];
}
const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'Cetli'
    }
  },
  {
    path: '/profil',
    name: 'profil',
    component: Profile,
    meta: {
      title: 'Cetli',
      requiresAuth: true,
      permission: ['admin', 'editor', 'viewer', 'performer', 'musician']
    }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Cetli',
      requiresAuth: true,
      permission: ['admin', 'editor', 'viewer', 'performer', 'musician']
    }
  },
  {
    path: '/eloadas-fajtak',
    name: 'eloadas-fajtak',
    component: EventTypeView,
    meta: {
      title: 'Cetli',
      requiresAuth: true,
      permission: ['admin', 'editor']
    }
  },
  {
    path: '/nevjegyzek',
    name: 'nevjegyzek',
    component: UsersView,
    meta: {
      title: 'Cetli',
      requiresAuth: true,
      permission: ['admin', 'editor']
    }
  },
  {
    path: '/hangszerek',
    name: 'hangszerek',
    component: InstrumentsView,
    meta: {
      title: 'Cetli',
      requiresAuth: true,
      permission: ['admin', 'editor']
    }
  },
  {
    path: '/tankeruletek',
    name: 'tankeruletek',
    component: SchoolDistrictsView,
    meta: {
      title: 'Cetli',
      requiresAuth: true,
      permission: ['admin', 'editor']
    }
  },
  {
    path: '/helyszinek',
    name: 'helyszinek',
    component: Addresses,
    meta: {
      title: 'Cetli',
      requiresAuth: true,
      permission: ['admin', 'editor', 'viewer']
    }
  },
  {
    path: '/kimutatas',
    name: 'kimutatas',
    component: StatView,
    meta: {
      title: 'Cetli',
      requiresAuth: true,
      permission: ['admin', 'editor', 'viewer', 'performer', 'musician']
    }
  },
  {
    path: '/elfelejtett-jelszo',
    name: 'elfelejtett-jelszo',
    component: ForgotPasswordView,
    meta: {
      title: 'Cetli'
    }
  },
  {
    path: '/403',
    name: '403',
    component: NoRightsView
  },
  {
    path: '/(.*)',
    component: PageNotFoundView
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = auth.currentUser;

  if (requiresAuth) {
    // Route requires authentication
    if (currentUser) {
      // User is authenticated, check if user has required roles
      try {
        const tokenResult = await currentUser.getIdTokenResult();
        const userRoles = tokenResult.claims?.roles as string[] || [];

        if (userRoles && hasRequiredRole(to, userRoles)) {
          // User has required roles to access the route
          next();
        } else {
          // User does not have required roles, redirect to 403 Forbidden page
          next('/403');
        }
      } catch (error) {
        console.error('Error fetching user token:', error);
        next('/login'); // Redirect to login if there's an error
      }
    } else {
      // User is not authenticated, redirect to login
      next('/login');
    }
  } else {
    // Route does not require authentication, proceed
    next();
  }
});

function hasRequiredRole(route: RouteLocationNormalizedGeneric, userRoles: string[]) {
  // Check if the route has a 'permission' meta field and the user has one of the required roles
  return (route.meta as unknown as RouteMeta).permission?.some(role => userRoles.includes(role));
}

export default router;
