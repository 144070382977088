import { Module } from 'vuex';
import { EventGroupState } from './types';
import { RootState } from '@/store/types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const eventGroupStore: Module<EventGroupState, RootState> = {
  namespaced: true,
  state: {
    eventGroups: [],
    eventGroupsLoading: false,
    selectedUserId: null,
  },
  actions,
  mutations,
  getters,
};

export default eventGroupStore;
