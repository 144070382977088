<template>
  <v-expansion-panel>
    <v-expansion-panel-title class="pl-0 pr-4 py-2">
      <v-list-item class="list-item">
        <v-list-item-title class="valign-center">{{ group.name }}</v-list-item-title>
        <v-list-item-subtitle class="items-separated-by-dot">
          <span>{{
            formatDate(group.startDate, 'MM.dd') +
            (group.endDate && group.startDate !== group.endDate
              ? ' - ' + formatDate(group.endDate, 'MM.dd')
              : '')
          }}</span>
          <span>{{ events.length }} {{ $tc('event', events.length) }}</span>
          <span v-if="group.distance && group.distance > 0">{{ group.distance + ' km' }}</span>
        </v-list-item-subtitle>
      </v-list-item>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <!-- eloadok -->
      <ParticipantList :participants="group.participants" />

      <!-- eloadasok -->
      <div v-if="events.length > 0" class="mt-10 mb-4 block-title bg-grey-lighten-4">{{ $t('events') }}</div>
      <v-expansion-panels v-if="events.length > 0">
        <EventListItem
          v-for="(event, index) in events"
          v-bind:key="index"
          v-bind:event="event"
          v-bind:inGroup="true"
          v-on:copyEvent="copyEvent(event.id)"
          v-on:editEvent="editEvent(event.id)"
          v-on:removeEventFromGroup="removeEventFromGroup(event.id)"
        />
      </v-expansion-panels>

      <!-- hotelek -->
      <div
        v-if="group.accomodation.length > 0 || group.accomodationNote"
        class="mt-10 mb-4 block-title bg-grey-lighten-4"
      >
        {{ $t('accomodation') }}
      </div>
      <v-expansion-panels v-if="group.accomodation.length > 0">
        <AddressListItem
          v-for="(id, index) in group.accomodation"
          v-bind:key="index"
          v-bind:id="id"
        />
      </v-expansion-panels>

      <!-- szallas megjegyzes -->
      <div
        class="pre-line"
        :class="group.accomodation.length > 0 ? 'mt-4' : ''"
        v-if="group.accomodationNote"
      >
        {{ group.accomodationNote }}
      </div>

      <!-- megjegyzes -->
      <div v-if="group.note" class="mt-10 mb-3 block-title bg-grey-lighten-4">{{ $t('note') }}</div>
      <div class="pre-line" v-if="group.note">{{ group.note }}</div>
      <v-divider class="mt-10 mb-4" v-if="isActionButtonGroup1Visible || isActionButtonGroup2Visible"></v-divider>

      <!-- gombok -->
      <v-row v-if="isActionButtonGroup1Visible">
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pa-0" v-if="!groupConfirmed">
          <v-btn color="blue-darken-2" variant="text" @click="confirmGroup()">{{ $t('accept') }}</v-btn>
        </v-col>
        <v-col cols="auto" class="py-0 text-green" v-if="groupConfirmed"
          >{{ $t('accepted') }}
          <!-- <v-btn depressed @click="unconfirmGroup()">Visszavonás</v-btn> -->
        </v-col>
      </v-row>

      <v-row v-if="isActionButtonGroup2Visible">
        <v-col cols="auto" class="pa-0">
          <v-btn color="red-darken-2" variant="text" @click="deleteGroup()">{{ $t('delete-tour') }}</v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pa-0">
          <v-btn color="blue-darken-2" variant="text" @click="editGroup()">{{ $t('edit-tour') }}</v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script>
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { db } from '@/main';
import i18n from '@/i18n';
import { formatDate } from '@/helpers/datetime';
import EventListItem from '@/components/list/EventListItem.vue';
import AddressListItem from '@/components/list/AddressListItem.vue';
import ParticipantList from '@/components/list/ParticipantList.vue';

export default {
  name: 'GroupListItem',
  props: ['group', 'date'],
  components: {
    EventListItem,
    AddressListItem,
    ParticipantList,
  },
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
    userCanEdit() {
      return this.user && ['admin', 'editor'].some(role => this.user?.userRoles?.includes(role));
    },
    userRoles() {
      return this.$store.getters['user/userRoles'];
    },
    events() {
      if (!this.group || !this.date) return [];

      const groupEvents = [...this.group.events];
      return groupEvents
        .filter(id => !!this.$store.getters['event/eventById'](id))
        .map(id => {
          const event = this.$store.getters['event/eventById'](id);
          return { id, ...event };
        })
        .filter(event => event.date == this.date)
        .sort((a, b) => {
          const dt1 = new Date(a.date + ' ' + a.startTime).getTime();
          const dt2 = new Date(b.date + ' ' + b.startTime).getTime();
          return dt1 > dt2 ? 1 : -1;
        });
    },
    groupConfirmed() {
      if (this.group && this.user) {
        const userId = this.user.uid;
        const participants = this.group.participants;
        if (participants.length == 0) {
          return false;
        }
        const participant = participants.find(participant => participant.userId == userId);
        if (!participant) {
          return false;
        }
        return participant.confirmed;
      } else {
        return false;
      }
    },
    isActionButtonGroup1Visible() {
      return this.user?.userRoles.some(role => ['performer', 'musician'].includes(role));
    },
    isActionButtonGroup2Visible() {
      return this.user?.userRoles.some(role => ['admin', 'editor'].includes(role));
    },
  },
  methods: {
    editGroup() {
      this.$emit('editGroup');
    },
    deleteGroup() {
      this.$emit('deleteGroup');
    },
    async confirmGroup() {
      if (this.group && this.user) {
        const { id, ...data } = JSON.parse(JSON.stringify(this.group));
        const userId = this.user.uid;
        data.participants.forEach(participant => {
          if (participant.userId == userId) {
            participant.confirmed = true;
          }
        });

        const collectionRef = collection(db, 'eventGroups');
        const docRef = doc(collectionRef, id);
        try {
          await setDoc(docRef, { ...data, updateTime: new Date().toISOString() });
          this.close();
        } catch (error) {
          this.$store.dispatch('setSnackbar', { text: i18n.t('command-unsuccessful-try-again') }, { root: true });
        }
      }
    },
    copyEvent(id) {
      this.$emit('copyEvent', id);
    },
    editEvent(id) {
      this.$emit('editEvent', id);
    },
    removeEventFromGroup(id) {
      this.$emit('removeEventFromGroup', id);
    },
    formatDate(date, format) {
      return formatDate(date, format);
    },
  },
  filters: {
    lowercase: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toLowerCase() + value.slice(1);
    },
  },
};
</script>
<style scoped>
.list-item-action {
  flex-direction: row;
  align-items: center;
}
.valign-center {
  display: flex;
  align-items: center;
}
.items-separated-by-dot span:after {
  content: '•';
  color: #ababab;
  margin-left: 10px;
  margin-right: 10px;
}
.items-separated-by-dot span {
  display: inline-block;
}
.items-separated-by-dot span:last-child:after {
  content: '';
}
.ws-pl {
  white-space: pre-line;
}
.pre-line {
  white-space: pre-line;
}
</style>
