<template>
  <v-expansion-panel v-if="address">
    <v-expansion-panel-title class="pl-0 pr-4 py-2">
      <v-list-item class="list-item">
        <v-list-item-title>{{ address.name }}</v-list-item-title>
        <v-list-item-subtitle><AddressText :address="address" /></v-list-item-subtitle>
      </v-list-item>
    </v-expansion-panel-title>
    <v-expansion-panel-text class="text-body-2">
      <AddressDetails :address="address" />

      <v-divider class="my-4" v-if="withActionButtons" />

      <v-row v-if="withActionButtons">
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pa-0">
          <v-btn color="blue-darken-2" variant="text" @click="edit()">{{ $t('edit') }}</v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>
<script>
import AddressText from '@/components/AddressText.vue';
import AddressDetails from '@/components/AddressDetails.vue';

export default {
  name: 'AddressListItem',
  props: ['id', 'withActionButtons'],
  components: {
    AddressText,
    AddressDetails
  },
  computed: {
    address() {
      return this.id && this.$store.getters['address/addressById'](this.id);
    },
  },
  methods: {
    edit() {
      this.$emit('edit');
    },
  },
};
</script>
