<template>
  <v-layout>
    <v-main>
      <v-progress-circular v-if="init" :size="50" color="amber" indeterminate />
      <router-view />
    </v-main>

    <v-navigation-drawer v-model="drawer" v-if="authUser && authUser.uid" class="pt-2">
      <div class="sidebar-content">
        <AppMenu />
        <VSpacer />
        <span class="text-grey p-3">v{{ appVersion }}</span>
      </div>
    </v-navigation-drawer>
  </v-layout>

  <v-snackbar v-model="snackbar.visible">
    {{ snackbar.text }}
    <template v-slot:actions>
      <v-btn color="red" variant="text" @click="closeSnackbar()">{{ $t('ok') }}</v-btn>
    </template>
  </v-snackbar>
  <!-- <div v-else>Nem támogatott böngésző</div> -->
</template>
<script>
import AppMenu from '@/components/menu/AppMenu.vue';
import { version } from '@/version';

export default {
  name: 'App',
  components: {
    AppMenu,
  },
  data: () => ({
    appVersion: version,
    drawer: true,
    snackbar: {
      visible: false,
      text: null,
      messages: {
        success: 'Sikeres művelet',
      },
    },
    isIndexedDBSupported: false,
  }),
  computed: {
    authUser() {
      return this.$store.getters['user/authUser'];
    },
    init() {
      return this.$store.getters['core/init'];
    },
    drawerVisible() {
      return this.$store.getters['core/drawerVisible'];
    },
    snackbarState() {
      return this.$store.getters['core/snackbar'];
    },
  },
  watch: {
    authUser(user) {
      if (user && user.uid && this.isIndexedDBSupported) {
        this.$store.dispatch('address/fetchAddresses');
        this.$store.dispatch('event/fetchEvents');
        this.$store.dispatch('eventGroup/fetchEventGroups');
        this.$store.dispatch('eventType/fetchEventTypes');
        this.$store.dispatch('instrument/fetchInstruments');
        this.$store.dispatch('role/fetchRoles');
        this.$store.dispatch('schoolDistrict/fetchSchoolDistricts');
        this.$store.dispatch('user/fetchUsers');
        this.$store.dispatch('user/fetchUserRoles');
      }
    },
    drawerVisible(bool) {
      this.drawer = bool;
    },
    snackbarState(snackbar) {
      if (snackbar?.text) {
        this.showSnackbar(snackbar.text, snackbar.duration ?? 0);
      }
    }
  },
  methods: {
    showSnackbar(text, duration = 3000) {
      this.snackbar.visible = true;
      this.snackbar.text = text;
      if (duration) {
        setTimeout(() => {
          this.closeSnackbar();
        }, duration);
      }
    },
    closeSnackbar() {
      this.snackbar.visible = false;
      this.snackbar.text = null;
    },
    clientWidth() {
      return Math.max(window.innerWidth, document.documentElement.clientWidth);
    },
    clientHeight() {
      return Math.max(window.innerHeight, document.documentElement.clientHeight);
    },
  },
  mounted() {
    this.isIndexedDBSupported = !!window.indexedDB;
  },
};
</script>
<style lang="scss">
.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.list-item {
  white-space: pre-line;
  overflow: hidden;
}
.expansion-panel-header {
  padding: 6px 18px 6px 6px;
}
.chips {
  display: flex;
  flex-wrap: wrap;
}
.v-chip .v-avatar {
  width: 25px !important;
}
.valign-center {
  display: flex;
  align-items: center;
}
</style>
