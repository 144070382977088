<template>
  <v-list density="compact" class="pt-0" v-if="props.address">
    <v-list-item class="list-item pa-0" v-if="props.address.city && props.address.address">
      <template v-slot:prepend>
        <v-icon icon="mdi-map-marker-outline"></v-icon>
      </template>
      <GoogleMapsLink :address="props.address" />
    </v-list-item>

    <v-list-item class="list-item pa-0" v-if="props.address.contactName">
      <template v-slot:prepend>
        <v-icon icon="mdi-account-box-outline"></v-icon>
      </template>
      <span>{{ props.address.contactName }}</span>
    </v-list-item>

    <v-list-item class="list-item pa-0" v-if="props.address.contactTelephone">
      <template v-slot:prepend>
        <v-icon icon="mdi-phone-outline"></v-icon>
      </template>
      <a :href="'tel:' + props.address.contactTelephone">{{ props.address.contactTelephone }}</a>
    </v-list-item>

    <v-list-item class="list-item pa-0" v-if="props.address.contactEmail">
      <template v-slot:prepend>
        <v-icon icon="mdi-email-outline"></v-icon>
      </template>
      <a :href="'mailto:' + props.address.contactEmail">{{ props.address.contactEmail }}</a>
    </v-list-item>

    <v-list-item class="list-item pa-0" v-if="props.address.note">
      <template v-slot:prepend>
        <v-icon icon="mdi-note-outline"></v-icon>
      </template>
      <span v-if="props.address.note">{{ props.address.note }}<br /></span>
    </v-list-item>
  </v-list>
</template>

<script setup>
import { defineProps } from 'vue';
import GoogleMapsLink from '@/components/GoogleMapsLink.vue';

const props = defineProps(['address']);
</script>
