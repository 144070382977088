import { Module } from 'vuex';
import { EventTypeState } from './types';
import { RootState } from '@/store/types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const eventTypeStore: Module<EventTypeState, RootState> = {
  namespaced: true,
  state: {
    eventTypes: [],
    eventTypesLoading: false,
    selectedUserId: null,
  },
  actions,
  mutations,
  getters,
};

export default eventTypeStore;
