<template>
  <div>
    <v-row class="ma-0 mb-6"> {{ $t('participants') }} </v-row>
    <div class="participant-list">
      <div>Név</div>
      <div>Autó</div>
      <ParticipantFormItem
        v-for="(row, index) in rows.byMusician"
        :key="index"
        :role="row.role"
        :instrument="row.instrument"
        :participant="row.participant"
        @change="updateParticipantForm($event)"
      />
      <ParticipantFormItem
        v-for="(row, index) in rows.byPerfomer"
        :key="index"
        :role="row.role"
        :participant="row.participant"
        @change="updateParticipantForm($event)"
      />
    </div>
  </div>
</template>
<script>
import { getArrayItemById } from '@/helpers/form.helper';
import ParticipantFormItem from './ParticipantFormItem.vue';

export default {
  name: 'ParticipantForm',
  props: ['participants'],
  components: { ParticipantFormItem },
  data() {
    return {
      participantList: {},
    };
  },
  computed: {
    participantRoles() {
      return this.$store.getters['user/participantRoles'];
    },
    instruments() {
      return this.$store.getters['instrument/instruments'];
    },
    rows() {
      const rows = {
        byMusician: [],
        byPerfomer: [],
      };

      this.instruments.forEach(instrument => {
        const participant = this.getParticipant('musician', instrument.id);
        rows.byMusician.push({ role: this.roleById('musician'), instrument, participant });
      });

      const performer = this.getParticipant('performer');
      rows.byPerfomer.push({ role: this.roleById('performer'), participant: performer });

      return rows;
    }
  },
  mounted() {
    if (this.participants?.length > 0) this.participants.forEach(p => this.participantList[p.userId] = p);
  },
  methods: {
    roleById(id) {
      return getArrayItemById(this.participantRoles, id);
    },
    getParticipant(roleId, instrumentId) {
      return this.participants?.find(p => p.roleId === roleId && (p.instrumentId ? p.instrumentId === instrumentId : true));
    },
    updateParticipantForm(payload) {
      if (payload.userIdBefore !== payload.participant.userId) delete this.participantList[payload.userIdBefore];
      if (payload.participant.userId) this.participantList[payload.participant.userId] = { ...payload.participant };

      this.$emit('update:participants', Object.values(this.participantList));
    }
  },
};
</script>
<style>
.participant-list {
  display: grid;
  grid-template-columns: auto 52px;
  gap: 1rem;
}
.no-hint .v-text-field__details {
  display: none;
}
.no-hint .v-input__slot {
  margin-bottom: 0;
}
</style>
