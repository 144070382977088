<template>
  <v-dialog persistent scrollable :model-value="visible" max-width="500">
    <v-form v-model="formValid" ref="form">
      <v-card v-if="!confirmDialogVisible">
        <v-card-title>{{ form.id ? $t('edit') : $t('add-new') }}</v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <v-text-field
                color="blue-darken-2"
                :label="$t('last-name')"
                v-model="form.data.lastname"
                required
                :rules="[rules.required]"
                validate-on="blur"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                color="blue-darken-2"
                :label="$t('first-name')"
                v-model="form.data.firstname"
                required
                :rules="[rules.required]"
                validate-on="blur"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                color="blue-darken-2"
                :label="$t('email')"
                v-model="form.data.email"
                required
                :rules="[rules.required, rules.email]"
                :disabled="!!id"
                validate-on="blur"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                color="blue-darken-2"
                v-mask="mask.telephone"
                :label="$t('telephone')"
                v-model="form.data.telephone"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                color="blue-darken-2"
                v-model="form.data.userRoles"
                :items="userRoles"
                item-title="name"
                item-value="id"
                :label="$t('role')"
                required
                :rules="[rules.required, rules.notEmptyArray]"
                validate-on="blur"
                multiple
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="form.data.userRoles?.includes('musician')">
            <v-col cols="12">
              <v-select
                color="blue-darken-2"
                v-model="form.data.instruments"
                :items="instruments"
                item-title="name"
                item-value="id"
                :label="$t('instruments')"
                multiple
                required
                :rules="[rules.notEmptyArray]"
                validate-on="blur"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-4 mt-md-0" v-if="error">
            <v-col cols="12" class="text-center">
              <span class="text-red">{{ $t('error.' + error) }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red-darken-2" variant="text" v-if="form.id" @click="confirmDialogVisible = true">{{
            $t('delete')
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="$emit('close')">{{ $t('cancel') }}</v-btn>
          <v-btn color="blue-darken-2" variant="text" v-bind:loading="loading" @click="save()">{{ $t('save') }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="confirmDialogVisible">
        <v-card-text class="confirm-text">{{ $t('confirm-to-delete') }}</v-card-text>
        <v-card-actions>
          <v-btn color="red-darken-2" variant="text" @click="remove()" v-bind:loading="deleting">{{ $t('delete') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-2" variant="text" @click="confirmDialogVisible = false">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { functions } from '@/main';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { rules } from '../../helpers/validator-rules';
import { mask } from 'vue-the-mask';

export default {
  name: 'UserDialog',
  props: ['visible', 'id'],
  emits: ['close'],
  directives: { mask },
  data() {
    return {
      form: {
        id: null,
        data: {
          firstname: null,
          lastname: null,
          email: null,
          telephone: null,
          userRoles: [],
          instruments: [],
          deleted: false,
        },
      },
      loading: false,
      deleting: false,
      error: null,
      formValid: false,
      confirmDialogVisible: false,
      mask: { telephone: '+36 ## ### ####' },
      rules,
    };
  },
  computed: {
    user() {
      return this.id && this.$store.getters['user/userById'](this.id);
    },
    userRoles() {
      return this.$store.getters['user/userRoles'];
    },
    instruments() {
      return this.$store.getters['instrument/instruments'];
    },
  },
  methods: {
    async save() {
      if (this.formValid) {
        this.loading = true;
        this.error = null;

        if (this.form.data.instruments.length === 0) {
          delete this.form.data.instruments;
        }

        if (this.form.id) {
          // update user
          const updateUserFunction = httpsCallable(functions, 'updateUser2');
          try {
            const result = await updateUserFunction({ id: this.form.id, data: this.form.data });
            this.$emit('close');
          } catch (error) {
            this.error = error.toString().substr(7);
          } finally {
            this.loading = false;
          }
        } else {
          // add new user
          const addUserFunction = httpsCallable(functions, 'addUser2');
          try {
            const result = await addUserFunction(this.form.data);
            this.$emit('close');
          } catch (error) {
            this.error = error.toString().substr(7);
          } finally {
            this.loading = false;
          }
        }
      } else {
        this.$refs.form.validate();
      }
    },
    async remove() {
      this.deleting = true;
      const deleteUserFunction = httpsCallable(functions, 'deleteUser2');
      try {
        const payload = { id: this.form.id };
        const result = await deleteUserFunction(payload);
        this.$emit('close');
      } catch (error) {
        this.showSnackbar(error.toString().substr(7));
      } finally {
        this.loading = false;
      }
    },
    showSnackbar(text) {
      this.$store.dispatch('core/setSnackbar', { text });
    },
  },
  created() {
    if (this.id && this.user) {
      const { id, ...data } = this.user;
      this.form.id = id;
      this.form.data = JSON.parse(JSON.stringify(data));
      if (this.form.data.instruments === undefined) this.form.data.instruments = [];
    }
  },
};
</script>
