<template>
  <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" :model-value="visible">
    <v-card>
      <v-toolbar color="blue-darken-3">
        <v-btn icon="mdi-close" @click="close()" />
        <v-toolbar-title v-if="form.id">{{ $t('edit-tour') }}</v-toolbar-title>
        <v-toolbar-title v-else>{{ $t('new-tour') }}</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn variant="text" :loading="loading" @click="save()">{{ $t('save') }}</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-form v-model="formValid" ref="form">
        <v-container fluid class="pa-4">
          <v-row justify="space-between" class="mt-2">
            <v-col cols="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.data.startDate"
                    :label="$t('start-date')"
                    persistent-hint
                    required
                    readonly
                    disabled
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                    ref="datepicker2"
                    v-model="isDatepickerVisible"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        v-model="form.data.endDate"
                        :label="$t('end-date')"
                        persistent-hint
                        v-bind="props"
                        readonly
                        clearable
                        @click:clear="datepickerValue = null"
                      />
                    </template>
                    <v-date-picker
                      :hide-header="true"
                      :min="form.data.startDate"
                      v-model="datepickerValue"
                      @update:modelValue="handleDatepickerChange"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('distance')"
                    suffix="km"
                    v-model="form.data.distance"
                    required
                    :rules="[rules.required, rules.numberOnly]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('tour-date')"
                    v-model="form.data.name"
                    required
                    :rules="[rules.required]"
                    class="pb-0"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :label="$t('accomodation')"
                    v-model="form.data.accomodation"
                    v-model:search-input="searchTerm"
                    @update:model-value="searchTerm = ''"
                    :items="addresses"
                    item-title="name"
                    item-value="id"
                    clearable
                    hide-selected
                    multiple
                    no-data-text="Nincs találat"
                    class="pb-0"
                  >
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                        v-bind="props"
                        :title="item.raw.name"
                      >
                        <v-list-item-subtitle><AddressText :address="item.raw" /></v-list-item-subtitle>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    auto-grow
                    rows="1"
                    row-height="24"
                    :label="$t('accomodationNote')"
                    v-model="form.data.accomodationNote"
                    class="pt-3 pb-0"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    auto-grow
                    rows="1"
                    row-height="24"
                    :label="$t('note')"
                    v-model="form.data.note"
                    class="pt-3 pb-0"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="4" lg="3">
              <ParticipantForm v-model:participants="form.data.participants" v-if="form.data.participants" />
            </v-col>

            <v-col cols="12" md="7" lg="4">
              <v-list
                lines="three"
                select-strategy="classic"
                v-model:selected="selectedUngroupedEventsIndex"
                v-if="ungroupedEventsByDate.length > 0">
                <v-container fluid class="px-0 pb-4">{{ $t('available-events-to-add') }}</v-container>
                  <div v-for="event in ungroupedEventsByDate" v-bind:key="event.id">

                    <v-list-item :value="event.id">
                      <template v-slot:prepend="{ isActive }">
                        <v-list-item-action start>
                          <v-checkbox-btn :model-value="isActive" color="blue-darken-2"></v-checkbox-btn>
                        </v-list-item-action>
                      </template>

                      <v-list-item-title>
                        <v-avatar start class="bg-grey-lighten-3 mr-2" size="24" v-if="event.eventType">
                          {{ eventTypeById(event.eventType)?.shortName ?? '' }}
                        </v-avatar>
                        <span>{{ event.startTime }}</span>
                        <span v-if="event.endTime">-</span>
                        <span>{{ event.endTime }}</span>
                      </v-list-item-title>

                      <template v-if="addressById(event.addressId)">
                        <v-list-item-subtitle>{{ addressById(event.addressId)?.name }}</v-list-item-subtitle>
                        <v-list-item-subtitle><AddressText :address="addressById(event.addressId)" /></v-list-item-subtitle>
                      </template>
                    </v-list-item>

                    <v-divider></v-divider>
                  </div>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { db } from '@/main';
import { userById, addressById } from '@/helpers/form.helper';
import { rules } from '../../helpers/validator-rules';
import { mask } from 'vue-the-mask';
import ParticipantForm from '../list/ParticipantForm.vue';
import { formatJSDate } from '@/helpers/datetime';
import AddressText from '@/components/AddressText.vue';

export default {
  name: 'GroupDialog',
  props: ['visible', 'id', 'startDate'],
  components: {
    ParticipantForm,
    AddressText,
  },
  directives: { mask },
  data() {
    return {
      loading: false,
      searchTerm: '',
      isDatepickerVisible: false,
      datepickerValue: null,
      form: {
        id: null,
        data: {
          name: null,
          startDate: this.startDate,
          endDate: null,
          events: [],
          participants: [],
          distance: null,
          accomodation: [],
          accomodationNote: null,
          note: null,
        },
      },
      formValid: false,
      participantId: null,
      ungroupedEvents: [],
      selectedUngroupedEventsIndex: [],
      eventsIndex: [],
      mask: { date: '####-##-##', time: '##:##' },
      rules,
    };
  },
  computed: {
    eventGroup() {
      return this.id && this.$store.getters['eventGroup/groupById'](this.id);
    },
    addresses() {
      return this.$store.getters['address/addresses'];
    },
    users() {
      return this.$store.getters['user/users'];
    },
    eventTypes() {
      return this.$store.getters['eventType/eventTypesInclDeleted'];
    },
    ungroupedEventsByDate() {
      return this.$store.getters['event/ungroupedEventsByDate'](this.startDate);
    },
  },
  methods: {
    handleDatepickerChange(value) {
      const formattedDate = formatJSDate(value, 'yyyy-MM-dd');
      this.form.data.endDate = formattedDate;
      this.isDatepickerVisible = false;
    },
    async save() {
      if (this.formValid) {
        this.loading = true;
        this.form.data.events = [...this.eventsIndex.map(i => this.form.data.events[i]), ...this.selectedUngroupedEventsIndex];
        if (this.form.id) {
          this.form.data.participants = this.form.data.participants.map(p => {
            const { userName, roleOrder, ...user } = p;
            return user;
          });
          const collectionRef = collection(db, 'eventGroups');
          const docRef = doc(collectionRef, this.form.id);
          try {
            await setDoc(docRef, { ...this.form.data, updateTime: new Date().toISOString() });
            this.close();
          } catch (error) {
            this.loading = false;
            this.showSnackbar(error);
          } finally {
            this.loading = false;
          }
        } else {
          const collectionRef = collection(db, 'eventGroups');
          try {
            await addDoc(collectionRef, { ...this.form.data, createTime: new Date().toISOString(), updateTime: new Date().toISOString() });
            this.close();
          } catch (error) {
            this.loading = false;
            this.showSnackbar(error);
          } finally {
            this.loading = false;
          }
        }
      } else {
        this.$refs.form.validate();
      }
    },
    close() {
      this.$emit('close');
    },
    userById(id) {
      return userById(this.users, id);
    },
    addressById(id) {
      return addressById(this.addresses, id);
    },
    showSnackbar(text) {
      this.$store.dispatch('core/setSnackbar', { text });
    },
    eventTypeById(id) {
      return this.eventTypes?.find(et => et.id === id) ?? '?';
    },
  },
  created() {
    if (this.id && this.eventGroup) {
      const { id, ...data } = this.eventGroup;
      this.form.id = id;
      this.form.data = JSON.parse(JSON.stringify(data));

      if (this.form.data.endDate) this.datepickerValue = new Date(this.form.data.endDate);

      this.eventsIndex = Array.from({ length: this.eventGroup.events.length }, (v, k) => k);
    }
  },
};
</script>
<style scoped>
.confirm-text {
  font-size: 1rem;
}
</style>
