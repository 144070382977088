<template>
  <a :href="url" v-if="url" class="text-blue-darken-2">{{ props.text }}</a>
  <!-- <v-btn variant="text" color="primary" :href="url" :text="props.text" class="email-link" /> -->
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { useStore } from 'vuex';

const props = defineProps(['persons', 'text']);

const store = useStore();
const users = computed(() => {
  return store.getters['user/usersWithParticipantRoles'];
});

const url = computed(() => {
  const recipients = [];
  props.persons?.forEach(p => {
    const user = users.value?.find(u => u.id === p.userId);
    if (!user) return;
    recipients.push(user?.lastname + ' ' + user?.firstname + ' <' + user?.email + '>');
  });
  if (!recipients.length) return;

  // let subject = '?subject=';
  // if (props.startDate) subject += props.startDate.replace(/-/g, '.');
  // if (props.endDate) subject += '-' + props.endDate.replace(/-/g, '.');
  // if (props.name) subject += ' ' + props.name;

  return 'mailto:' + recipients.join(',');
});
</script>

<!-- <style lang="scss" scoped>
.email-link {
  text-transform: unset;
}
</style> -->
