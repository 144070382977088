import { MutationTree } from 'vuex';
import { InstrumentState } from './types';
import { Instrument } from 'types';

const addInstruments = (state: InstrumentState, payload: Instrument[]) => state.instruments = payload;

const setInstrumentsLoading = (state: InstrumentState, payload: boolean) => state.instrumentsLoading = payload;

const mutations: MutationTree<InstrumentState> = {
  addInstruments,
  setInstrumentsLoading
};

export default mutations;
