import { GetterTree } from 'vuex';
import { MusicEventState } from './types';
import { RootState } from '@/store/types';
import { MusicEvent, MusicTour, UserRoleId } from 'types';

const events = (state: MusicEventState) => {
  return state.events?.filter(item => !item.deleted) ?? [];
};

const eventsByDate = (state: MusicEventState, getters: any) => (date: string) => {
  if (!date) return [];

  return getters.events.filter((event: MusicEvent) => event.date == date);
};

const eventById = (state: MusicEventState, getters: any) => (id: string) => {
  return getters.events.find((item: MusicEvent) => item.id === id);
};

const ungroupedEventsByDate = (state: MusicEventState, getters: any, rootState: RootState, rootGetters: any) => (date: string) => {
  const events = getters.events;
  if (date && events?.length > 0) {
    return events
      .filter((event: MusicEvent) => event.date == date)
      .filter((event: MusicEvent) => {
        const authUser = rootGetters['user/authUser'];
        const participants = event.participants;
        if (authUser?.userRoles.some((role: UserRoleId) => ['musician', 'performer'].includes(role))) {
          if (participants.length == 0) {
            return false;
          }
          return participants.some(participant => participant.userId == authUser?.uid);
        } else if (state.selectedUserId) {
          return participants.some(participant => participant.userId == state.selectedUserId);
        } else {
          return true;
        }
      })
      .filter((event: MusicEvent) => {
        const eventGroups: MusicTour[] = rootGetters['eventGroup/groupsByDate'](date).map((group: MusicTour) => {
          const events = group.events
            .filter(id => !!rootGetters['event/eventById'](id))
            .map(id => {
              const event = rootGetters['event/eventById'](id) ?? null;
              return { id, ...event };
            })
            .filter(event => event.date == date)
            .sort((a, b) => {
              const dt1 = new Date(a.date + ' ' + a.startTime).getTime();
              const dt2 = new Date(b.date + ' ' + b.startTime).getTime();
              return dt1 > dt2 ? 1 : -1;
            });
          // .map(event => event.id)
          return { ...group, events };
        });
        if (eventGroups.length > 0) {
          let eventIds: string[] = [];
          eventGroups.forEach(group => {
            const events = group.events as MusicEvent[];
            eventIds = [...eventIds, ...events.map(event => event.id)];
          });
          return !eventIds.includes(event.id);
        } else {
          return true;
        }
      })
      .sort((a: any, b: any) => {
        const dt1 = new Date(a.date + ' ' + a.startTime).getTime();
        const dt2 = new Date(b.date + ' ' + b.startTime).getTime();
        return dt1 > dt2 ? 1 : -1;
      });
  } else {
    return [];
  }
};

const selectedUserId = (state: MusicEventState) => {
  return state.selectedUserId;
};

const getters: GetterTree<MusicEventState, RootState> = {
  events,
  eventsByDate,
  eventById,
  ungroupedEventsByDate,
  selectedUserId,
};

export default getters;
