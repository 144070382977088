import { ActionContext, ActionTree } from 'vuex';
import { collection, orderBy, query, QuerySnapshot, where } from 'firebase/firestore';
import { listener } from '@/services/firebase';
import { AddressState } from './types';
import { RootState } from '@/store/types';
import { db } from '@/main';
import { readAllFromIndexedDB, writeToIndexedDB } from '@/services/indexedDB';

let unsubscribeVar: any;

const fetchAddresses = async (context: ActionContext<AddressState, RootState>): Promise<void> => {
  context.commit('setAddressLoading', true);

  const { data: initialData, lastUpdateTime: initialLastUpdateTime } = await readAllFromIndexedDB('addresses');
  context.commit('addAddresses', initialData);

  const collectionRef = collection(db, 'addresses');

  const callback = async (snapshot: QuerySnapshot) => {
    console.log('snap/addr', snapshot.docs.length);
    if (snapshot.docs.length === 0) {
      context.commit('setAddressLoading', false);
      return;
    }

    snapshot.docs.forEach(async doc => {
      await writeToIndexedDB('addresses', { id: doc.id, ...doc.data() });
    });
    const { data, lastUpdateTime } = await readAllFromIndexedDB('addresses');
    context.commit('addAddresses', data);
    context.commit('setAddressLoading', false);
    unsubscribeVar();
    unsubscribeVar = listener(
      query(collectionRef, where('updateTime', '>', lastUpdateTime), orderBy('updateTime')),
      callback
    );
  };

  unsubscribeVar = listener(
    initialLastUpdateTime
      ? query(collectionRef, where('updateTime', '>', initialLastUpdateTime), orderBy('updateTime'))
      : query(collectionRef, orderBy('updateTime')),
    callback
  );
};

const setAddressSearchTerm = (context: ActionContext<AddressState, RootState>, payload: string): void => {
  context.commit('setAddressSearchTerm', payload);
};

const unsubscribe = (): void => unsubscribeVar();

const actions: ActionTree<AddressState, RootState> = {
  fetchAddresses,
  setAddressSearchTerm,
  unsubscribe,
};

export default actions;
