<template>
  <template v-if="props?.address?.city && props?.address?.address">
    <template v-if="props?.address?.zip">{{ `${props.address.zip} ` }}</template>
    {{ `${props.address.city}, ${props.address.address}` }}
  </template>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps(['address']);
</script>
