<template>
  <span class="ws-pl" v-if="instrument">{{ instrument.name }}</span>
</template>

<script setup>
import { computed, defineProps, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  instrumentId: String,
});

const store = useStore();
const instruments = computed(() => store.getters['instrument/instruments']);

const instrument = ref();
onBeforeMount(() => {
  if (!props.instrumentId) return;
  instrument.value = instruments.value?.find(instrument => instrument.id === props.instrumentId);
});
</script>
