import { GetterTree } from 'vuex';
import { EventGroupState } from './types';
import { RootState } from '@/store/types';
import { UserRoleId } from 'types';
import { isBetweenDates } from '@/helpers/datetime';

const groups = (state: EventGroupState) => {
  return state.eventGroups ?? [];
};

const groupsByDate = (state: EventGroupState, getters: any, rootState: RootState, rootGetters: any) => (date: string) => {
  if (rootGetters['user/authUser']?.uid && state.eventGroups.length > 0) {
    const selectedUserId = state.selectedUserId;
    return state.eventGroups
      .filter(group => !group.deleted)
      .filter(group => {
        if (group.endDate) {
          return isBetweenDates(date, group.startDate, group.endDate);
        } else {
          return group.startDate === date;
        }
      })
      .filter(group => {
        const authUser = rootGetters['user/authUser'];
        const participants = group.participants;
        if (authUser?.userRoles.some((role: UserRoleId) => ['musician', 'performer'].includes(role))) {
          if (participants.length === 0) return false;
          return participants.some(participant => participant.userId === authUser?.uid);
        } else if (selectedUserId) {
          return participants.some(participant => participant.userId === selectedUserId);
        } else {
          return true;
        }
      });
  } else {
    return [];
  }
};

const groupById = (state: EventGroupState, getters: any, rootState: RootState, rootGetters: any) => (id: string) => {
  if (!id) return;

  const group = state.eventGroups?.find(group => group.id == id);
  if (!group) return;

  group.events = group.events
    .filter(eventId => {
      const event = rootGetters['event/eventById'](eventId);
      return !!event;
    })
    .map(eventId => rootGetters['event/eventById'](eventId))
    .sort((a, b) => {
      const dt1 = new Date(a.date + ' ' + a.startTime).getTime();
      const dt2 = new Date(b.date + ' ' + b.startTime).getTime();
      return dt1 > dt2 ? 1 : -1;
    })
    .map(event => event.id);
  return group;
};

const eventGroupsLoading = (state: EventGroupState) => {
  return state.eventGroupsLoading;
};

const selectedUserId = (state: EventGroupState) => {
  return state.selectedUserId;
};

const getters: GetterTree<EventGroupState, RootState> = {
  groups,
  groupsByDate,
  groupById,
  eventGroupsLoading,
  selectedUserId,
};

export default getters;
