import { createI18n } from 'vue-i18n';

const messages = {
  hu: {
    'accept': 'Elfogadás',
    'accepted': 'Elfogadva',
    'accomodation': 'Szállás',
    'accomodationNote': 'Szállás megjegyzés',
    'add': 'Hozzáadás',
    'address': 'Cím',
    'addresses': 'Helyszínek',
    'add-new': 'Új hozzáadása',
    'available-events-to-add': 'Turnéhoz adható előadások',
    'cancel': 'Mégse',
    'class': 'Osztály',
    'close': 'Bezárás',
    'comes-with-car': 'Autóval jön',
    'confirm-to-delete': 'Biztosan törli?',
    'confirm-to-remove': 'Biztosan eltávolítja?',
    'contact-person': 'Kapcsolattartó',
    'contacts': 'Névjegyzék',
    'contributor': 'Közreműködő',
    'participants': 'Közreműködők',
    'copy-event': 'Előadás másolása',
    'current-password': 'Jelenlegi jelszó',
    'date': 'Dátum',
    'delete': 'Törlés',
    'delete-tour': 'Turné törlése',
    'display-on-map': 'Megjelenítés térképen',
    'distance': 'Távolság',
    'download-data': 'Adatletöltés',
    'edit': 'Szerkesztés',
    'edit-profile': 'Profil szerkesztése',
    'edit-tour': 'Turné szerkesztése',
    'email': 'Email',
    'email-address': 'Email cím',
    'empty-list': 'Üres lista',
    'end-date': 'Befejező dátum',
    'end-time': 'Befejezés',
    'error': {
      'auth/email-already-exists': 'A megadott email cím már használatban van',
      'auth/user-not-found': 'A megadott email cím nem található'
    },
    'event': 'Előadás | Előadás',
    'events': 'Előadások',
    'event-type': 'Előadás fajta',
    'event-types': 'Előadás fajták',
    'first-name': 'Keresztnév',
    'forgotten-password': 'Elfelejtett jelszó',
    'have-you-forgotten-your-password': 'Elfelejtette jelszavát?',
    'initial': 'Iniciálé',
    'instrument': 'Hangszer',
    'instruments': 'Hangszerek',
    'insufficient-rights-to-access-page': 'Nincs jogosultsága az oldal megtekintésére',
    'login': 'Bejelentkezés',
    'musician': 'Zenész',
    'name': 'Név',
    'name-updated': 'Név frissítve',
    'naming': 'Elnevezés',
    'new-contributor': 'Új közreműködő',
    'new-event': 'Új előadás',
    'new-password': 'Új jelszó',
    'new-password-again': 'Új jelszó ismét',
    'new-tour': 'Új turné',
    'next': 'Tovább',
    'note': 'Megjegyzés',
    'no-events-found': 'Nincsenek előadások',
    'no-results': 'Nem található adat',
    'no-tours-found': 'Nincsenek turnék',
    'last-name': 'Vezetéknév',
    'logout': 'Kijelentkezés',
    'ok': 'Ok',
    'password': 'Jelszó',
    'performer': 'Előadó',
    'performer-role': 'Előadói szerepkör',
    'place': 'Helyszín',
    'places': 'Helyszínek',
    'remove': 'Eltávolítás',
    'reset-requested': 'Levelet küldtünk a megadott email címre. A befejezéshez kérjük kattintson a levélben található linkre.',
    'requested-page-can-not-be-found': 'A kért oldal nem található',
    'role': 'Munkakör',
    'roles': 'Munkakörök',
    'save': 'Mentés',
    'settlement': 'Település',
    'school': 'Iskola',
    'school-district': 'Tankerület',
    'school-districts': 'Tankerületek',
    'search': 'Keresés',
    'site-title': 'BFTZ Cetli',
    'short-name': 'Rövid név',
    'start-time': 'Kezdés',
    'start-date': 'Kezdő dátum',
    'stat': 'Kimutatás',
    'telephone': 'Telefon',
    'tour-date': 'Turné név',
    'tours': 'Turnék',
    'website': 'Weboldal',
    'zip': 'Irsz',
    'command-successful': 'Sikeres művelet',
    'command-unsuccessful-try-again': 'Sikertelen művelet, próbálja újra később'
  },
};

const i18n = createI18n({
  // locale: (import.meta as any).env.VUE_APP_I18N_LOCALE || 'hu',
  // fallbackLocale: (import.meta as any).env.VUE_APP_I18N_FALLBACK_LOCALE || 'hu',
  locale: 'hu',
  fallbackLocale: 'hu',
  messages,
});

export default i18n;
