import { MutationTree } from 'vuex';
import { UserState } from './types';
import { User, UserRole, UserRoleId } from 'types';

const setUser = (state: UserState, payload: User) => state.authUser = payload;

const setAuthUserRoles = (state: UserState, payload: UserRoleId[]) => {
  if (!state.authUser) return;
  state.authUser.userRoles = payload;
};

const addUser = (state: UserState, payload: User) => {
  const fullName = payload.lastname + ' ' + payload.firstname;
  state.users.push({ ...payload, fullName });
};

const addUsers = (state: UserState, payload: User[]) => {
  state.users = payload.map(user => {
    const fullName = user.lastname + ' ' + user.firstname;
    return { ...user, fullName };
  });
};

const setUserSearchTerm = (state: UserState, payload: string) => state.userSearchTerm = payload?.toLowerCase() ?? null;

const setUsersLoading = (state: UserState, payload: boolean) => state.usersLoading = payload;

const addUserRoles = (state: UserState, payload: UserRole[]) => state.userRoles = payload;

const mutations: MutationTree<UserState> = {
  setUser,
  setAuthUserRoles,
  addUser,
  addUsers,
  setUserSearchTerm,
  setUsersLoading,
  addUserRoles
};

export default mutations;
