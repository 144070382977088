<template>
  <div class="monthpicker">
    <div class="monthpicker__header">
      <v-btn
        density="comfortable"
        variant="plain"
        icon="mdi-chevron-left"
        @click="prevYear"
      />
      <div class="monthpicker__header__text">{{ currentYear }}</div>
      <v-btn
        density="comfortable"
        variant="plain"
        icon="mdi-chevron-right"
        @click="nextYear"
      />
    </div>
    <div class="monthpicker__body months">
      <template v-for="index in monthIndexes" :key="index">
        <v-btn
          variant="tonal"
          density="comfortable"
          class="month"
          elevation="0"
          rounded="xl"
          @click="selectMonth(index)">
          {{ monthLabels[index] }}
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, onMounted, ref } from 'vue';
import { DateTime } from 'luxon';
import { monthLabels } from '@/helpers/datetime';

const props = defineProps({
  modelValue: {
    type: String,
    default: DateTime.local(),
  },
});
onMounted(() => {
  selectedDate.value = DateTime.fromISO(props.modelValue);
});

const emit = defineEmits(['update:modelValue']);

const selectedDate = ref(DateTime.local());
const monthIndexes = Array.from({ length: 12 }, (_, index) => index);

const currentYear = computed(() => selectedDate.value.year);

const selectMonth = index => {
  const month = (index + 1).toString().padStart(2, '0');
  const day = '01';
  emit('update:modelValue', `${currentYear.value}-${month}-${day}`);
};

const prevYear = () => {
  selectedDate.value = selectedDate.value.minus({ years: 1 }).startOf('month');
};
const nextYear = () => {
  selectedDate.value = selectedDate.value.plus({ years: 1 }).startOf('month');
};
</script>

<style lang="scss">
.monthpicker {
    height: auto;
    overflow: hidden;
    position: relative;
    z-index: 0;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 0 auto;
    margin-bottom: 1rem;
    width: 100%;

    &__header {
      padding: 4px 16px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      position:relative;
      margin-bottom: 1rem;

      &__text {
        flex: 1;
        text-align: center;
        position: relative;
        overflow: hidden;
      }
    }

    &__body {
      position: relative;
      padding: 0 12px;

      .v-btn {
        z-index: auto;
        margin: 0;
        font-size: 12px;
      }
    }

    & > div {
        width:100%;
    }

  .months {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;

    .month {
      position: relative;
      font-size: 0.75rem;

      &.weekend {
        color: gray;
      }
    }
  }

  .events {
    height: 8px;
    width: 100%;
    position: absolute;
    left: 0;
    text-align: center;
    white-space: pre;
    bottom: 6px;

    & > div {
      border-radius: 50%;
      display: inline-block;
      height: 8px;
      width: 8px;
      margin: 0 1px;
    }

    .green.lighten-1 {
      background-color: #66bb6a!important;
      border-color: #66bb6a!important;
    }

    .red.lighten-1 {
      background-color: #ef5350!important;
      border-color: #ef5350!important;
    }
  }
}
</style>
