import { Address, User, UserRole } from 'types';

export const userById = (users: User[], id: string) => {
  if (!id) return;
  return users?.find(user => user.id === id);
};

export const roleById = (roles: UserRole[], id: string) => {
  if (!id) return;
  return roles?.find(role => role.id === id);
};

export const addressById = (addresses: Address[], id: string) => {
  if (!id) return;
  return addresses?.find(address => address.id === id);
};

export const getArrayItemById = (array: any[], id: string | number) => {
  if (!id) return;
  return array?.find(item => item.id === id);
};
