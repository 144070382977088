import { MutationTree } from 'vuex';
import { CoreState } from './types';

const setInit = (state: CoreState, payload: any) => state.init = payload;

const toggleDrawer = (state: CoreState) => state.drawerVisible = !state.drawerVisible;

const setSnackbar = (state: CoreState, payload: any) => state.snackbar = payload;

const mutations: MutationTree<CoreState> = {
  setInit,
  toggleDrawer,
  setSnackbar,
};

export default mutations;
