import { GetterTree } from 'vuex';
import { RoleState } from './types';
import { RootState } from '@/store/types';
import { Role } from 'types';

const orderedRoles = (state: RoleState) => {
  return state.roles?.sort((a, b) => a.name?.localeCompare(b.name)) ?? [];
};

const roles = (state: RoleState, getters: any) => {
  return getters.orderedRoles.filter((item: Role) => !item.deleted && !Object.prototype.hasOwnProperty.call(item, 'isAdmin'));
};

const rolesInclDeleted = (state: RoleState, getters: any) => {
  return getters.orderedRoles.filter((item: Role) => !Object.prototype.hasOwnProperty.call(item, 'isAdmin'));
};

const rolesLoading = (state: RoleState) => {
  return state.rolesLoading;
};

const performerRoles = (state: RoleState) => {
  return state.roles
    .filter(
      (item: Role) =>
        !item.deleted &&
        Object.prototype.hasOwnProperty.call(item, 'isPerformer') &&
        item.isPerformer
    )
    .sort((a, b) => a.order - b.order);
};

const getters: GetterTree<RoleState, RootState> = {
  orderedRoles,
  roles,
  rolesInclDeleted,
  rolesLoading,
  performerRoles
};

export default getters;
