import { GetterTree } from 'vuex';
import { InstrumentState } from './types';
import { RootState } from '@/store/types';
import { Instrument } from 'types';

const orderedInstruments = (state: InstrumentState) => {
  return state.instruments?.sort((a, b) => a.name?.localeCompare(b.name)) ?? [];
};

const instruments = (state: InstrumentState, getters: any) => {
  return getters.orderedInstruments.filter((item: Instrument) => !item.deleted);
};

const instrumentById = (state: InstrumentState, getters: any) => (id: string) => {
  return getters.orderedInstruments.find((item: Instrument) => item.id === id);
};

const instrumentsInclDeleted = (state: InstrumentState, getters: any) => {
  return getters.orderedInstruments;
};

const instrumentsLoading = (state: InstrumentState) => {
  return state.instrumentsLoading;
};

const getters: GetterTree<InstrumentState, RootState> = {
  orderedInstruments,
  instruments,
  instrumentById,
  instrumentsInclDeleted,
  instrumentsLoading
};

export default getters;
