<template>
  <div>
    <v-app-bar flat color="blue-darken-3">
      <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
      <v-toolbar-title style="width: 300px" class="hidden-sm-and-down ml-0 pl-4">{{ $t('events') }}</v-toolbar-title>
      <v-spacer />
      <div class="date-time-string mr-4">
        <span class="hidden-sm-and-down">{{ formatDate(datepicker, 'yyyy.MM.dd') }}</span>
        <span class="hidden-md-and-up">{{
          capitalizeFirstLetter(formatDate(datepicker, 'MMM.')) + ' ' + formatDate(datepicker, 'dd')
        }}</span>
        <span class="hidden-sm-and-down">{{ ' ' + getDayName(datepicker) }}</span>
      </div>
      <v-btn icon @click.stop="datepicker = subtractDay(datepicker, 1)">
        <v-icon icon="mdi-arrow-left" />
      </v-btn>
      <v-btn icon @click.stop="datepicker = addDay(datepicker, 1)">
        <v-icon icon="mdi-arrow-right" />
      </v-btn>
      <v-btn icon @click.stop="drawerRight = !drawerRight">
        <v-icon v-if="user && user.role !== 'performer'" icon="mdi-calendar-account-outline" />
        <v-icon v-if="user && user.role == 'performer'" icon="mdi-calendar-month-outline" />
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawerRight" location="right" width="300">
      <v-btn class="hidden-md-and-up ma-4 mr-2" icon @click.stop="drawerRight = !drawerRight">
        <v-icon icon="mdi-arrow-right" />
      </v-btn>
      <span class="hidden-md-and-up">{{ $t('close') }}</span>
      <v-skeleton-loader :loading="loadingState" type="image">
        <v-select
          v-if="isUserSelectorVisible"
          variant="outlined"
          class="mx-6 mt-8"
          v-model="selectedUserId"
          :change="filterByUser()"
          :items="users"
          :item-title="(item) => item.lastname + ' ' + item.firstname"
          item-value="id"
          :label="$t('performer')"
        ></v-select>
        <v-divider v-if="isUserSelectorVisible" class="mt-2 mb-6"></v-divider>
        <date-picker v-model="datepicker" :events="dots"></date-picker>
      </v-skeleton-loader>
    </v-navigation-drawer>

    <!-- <v-container :class="{ 'container--slim' : $vuetify.display.smAndDown }"> -->
    <v-container>
      <!-- <v-row :class="{ 'mx-3' : $vuetify.display.lgAndUp }"> -->
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader type="article@4" v-if="loadingState" />
          <template v-else>
            <v-row class="valign-center mx-0 my-4">
              {{ $t('tours') }}
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0" v-if="userCanEdit">
                <v-btn size="small" variant="outlined" color="primary" @click.stop="addGroup()">
                  <v-icon start icon="mdi-plus" />
                  {{ $t('new-tour') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-expansion-panels v-if="groupsByDate.length > 0">
              <v-group-list-item
                v-for="group in groupsByDate"
                v-bind:key="group.id"
                v-bind:group="group"
                v-bind:date="datepicker"
                v-on:edit-group="editGroup(group.id)"
                v-on:delete-group="showDeleteGroupDialog(group.id)"
                v-on:remove-event-from-group="showRemoveGroupEventDialog($event, group.id)"
                v-on:copy-event="copyEvent($event)"
                v-on:edit-event="editEvent($event)"
              />
            </v-expansion-panels>

            <v-expansion-panels v-else>
              <v-expansion-panel disabled>
                <v-expansion-panel-title hide-actions>
                  {{ $t('no-tours-found') }}
                </v-expansion-panel-title>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-row class="valign-center mx-0 mt-12 mb-4">
              {{ $t('events') }}
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0" v-if="userCanEdit">
                <v-btn size="small" variant="outlined" color="primary" @click.stop="addEvent()">
                  <v-icon icon="mdi-plus" start />
                  {{ $t('new-event') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-expansion-panels class="mb-12" v-if="ungroupedEventsByDate.length > 0">
              <v-event-list-item
                v-for="event in ungroupedEventsByDate"
                v-bind:key="event.id"
                v-bind:event="event"
                v-on:copyEvent="copyEvent(event.id)"
                v-on:editEvent="editEvent(event.id)"
                v-on:deleteEvent="showDeleteEventDialog(event.id)"
              />
            </v-expansion-panels>

            <v-expansion-panels class="mb-12" v-else>
              <v-expansion-panel disabled>
                <v-expansion-panel-title hide-actions>
                  {{ $t('no-events-found') }}
                </v-expansion-panel-title>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <!-- előadás hozzáadása / szerkesztése / másolása -->
    <v-event-dialog
      v-if="eventDialogVisible"
      v-bind:visible="eventDialogVisible"
      v-bind:date="datepicker"
      v-bind:id="eventId"
      v-bind:copying="copying"
      v-on:close="closeEventDialog()"
    ></v-event-dialog>

    <!-- előadás törlése -->
    <v-confirm-dialog
      v-if="deleteEventDialogVisible"
      v-bind:visible="deleteEventDialogVisible"
      :text="$t('confirm-to-delete')"
      :actionText="$t('delete')"
      v-on:confirmed="deleteEvent()"
      v-on:close="closeDeleteEventDialog()"
    ></v-confirm-dialog>

    <!-- turné hozzáadása / szerkesztése -->
    <v-group-dialog
      v-if="groupDialogVisible"
      v-bind:visible="groupDialogVisible"
      v-bind:startDate="datepicker"
      v-bind:id="groupId"
      v-on:close="closeGroupDialog()"
    ></v-group-dialog>

    <!-- turné törlése -->
    <v-confirm-dialog
      v-if="deleteGroupDialogVisible"
      v-bind:visible="deleteGroupDialogVisible"
      :text="$t('confirm-to-delete')"
      :actionText="$t('delete')"
      v-on:confirmed="deleteGroup()"
      v-on:close="closeDeleteGroupDialog()"
    ></v-confirm-dialog>

    <!-- előadás eltávolítása turnéból -->
    <v-confirm-dialog
      v-if="removeGroupEventDialogVisible"
      v-bind:visible="removeGroupEventDialogVisible"
      :text="$t('confirm-to-remove')"
      :actionText="$t('remove')"
      v-on:confirmed="removeGroupEvent()"
      v-on:close="closeRemoveGroupEventDialog()"
    ></v-confirm-dialog>
  </div>
</template>
<script>
import { addDoc, collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '@/main';
import { getDayName, addTime, formatDate, getDateNow, isAfterOrSameDate, addDay, subtractDay } from '../helpers/datetime';
import { capitalizeFirstLetter } from '../helpers/string.helper';
import ConfirmDialog from '@/components/dialog/ConfirmDialog.vue';
import GroupDialog from '@/components/dialog/GroupDialog.vue';
import EventDialog from '@/components/dialog/EventDialog.vue';
import GroupListItem from '@/components/list/GroupListItem.vue';
import EventListItem from '@/components/list/EventListItem.vue';
import DatePicker from '@/components/DatePicker.vue';
export default {
  name: 'HomeView',
  components: {
    'v-confirm-dialog': ConfirmDialog,
    'v-group-dialog': GroupDialog,
    'v-event-dialog': EventDialog,
    'v-group-list-item': GroupListItem,
    'v-event-list-item': EventListItem,
    'date-picker': DatePicker,
  },
  data() {
    return {
      drawerRight: null,
      datepicker: getDateNow(),
      eventDialogVisible: false,
      event: null,
      eventId: null,
      copying: false,
      deleteEventDialogVisible: false,
      removeEventDialogVisible: false,

      groupDialogVisible: false,
      groupId: null,
      deleteGroupDialogVisible: false,

      removeGroupEventDialogVisible: false,

      selectedUserId: '0',
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
    userCanEdit() {
      return this.user && ['admin', 'editor'].some(role => this.user?.userRoles?.includes(role));
    },
    isUserSelectorVisible() {
      return this.user?.userRoles?.some(role => ['admin', 'editor'].includes(role)) ?? false;
    },
    users() {
      const all = [{ id: '0', lastname: 'Minden előadó', firstname: '' }];
      const users = this.$store.getters['user/filteredUsersBySearchTerm'];
      if (!users) {
        return;
      }
      return [...all, ...users];
    },
    eventsByDate() {
      return this.$store.getters['event/eventsByDate'](this.datepicker);
    },
    eventDots() {
      return this.$store.getters.eventDots;
    },
    groupsByDate() {
      return this.$store.getters['eventGroup/groupsByDate'](this.datepicker);
    },
    groupById() {
      return this.$store.getters['eventGroup/groupById'](this.groupId);
    },
    ungroupedEventsByDate() {
      return this.$store.getters['event/ungroupedEventsByDate'](this.datepicker);
    },
    loadingState() {
      return this.$store.getters.loadingState;
    },
    dots() {
      return this.$store.getters['core/dots'];
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch('core/toggleDrawer', null);
    },
    showSnackbar(text) {
      this.$store.dispatch('core/setSnackbar', { text });
    },

    // turné létrehozása
    addGroup() {
      this.groupDialogVisible = true;
    },
    editGroup(id) {
      this.groupId = id;
      this.groupDialogVisible = true;
    },
    closeGroupDialog() {
      this.groupId = null;
      this.groupDialogVisible = false;
    },

    // OK: turné törlése
    showDeleteGroupDialog(id) {
      this.groupId = id;
      this.deleteGroupDialogVisible = true;
    },
    async deleteGroup() {
      const collectionRef = collection(db, 'eventGroups');
      const docRef = doc(collectionRef, this.groupId);
      try {
        await updateDoc(docRef, {
          deleted: true,
          updateTime: new Date().toISOString(),
        });
        this.closeDeleteGroupDialog();
      } catch (error) {
        this.showSnackbar(error);
      }
    },
    closeDeleteGroupDialog() {
      this.groupId = null;
      this.deleteGroupDialogVisible = false;
    },

    // OK: előadás létrehozása/szerkesztése/másolása
    addEvent() {
      this.eventDialogVisible = true;
    },
    editEvent(id) {
      this.eventId = id;
      this.eventDialogVisible = true;
    },
    copyEvent(id) {
      this.eventId = id;
      this.copying = true;
      this.eventDialogVisible = true;
    },
    closeEventDialog() {
      this.eventId = null;
      this.copying = false;
      this.eventDialogVisible = false;
    },

    // OK: előadás törlése
    showDeleteEventDialog(id) {
      this.eventId = id;
      this.deleteEventDialogVisible = true;
    },
    async deleteEvent() {
      const collectionRef = collection(db, 'events');
      const docRef = doc(collectionRef, this.eventId);
      try {
        await updateDoc(docRef, {
          deleted: true,
          updateTime: new Date().toISOString(),
        });
        this.closeDeleteEventDialog();
      } catch (error) {
        this.showSnackbar(error);
      }
    },
    closeDeleteEventDialog() {
      this.eventId = null;
      this.deleteEventDialogVisible = false;
    },

    // OK: előadás eltávolítása turnéból
    showRemoveGroupEventDialog(eventId, groupId) {
      this.eventId = eventId;
      this.groupId = groupId;
      this.removeGroupEventDialogVisible = true;
    },
    async removeGroupEvent() {
      if (!this.groupById) return;

      const { id, ...group } = this.groupById;
      const index = group.events.findIndex(id => id === this.eventId);
      group.events.splice(index, 1);

      const collectionRef = collection(db, 'eventGroups');
      const docRef = doc(collectionRef, this.form.id);
      try {
        await setDoc(docRef, { ...group, updateTime: new Date().toISOString() });
        this.closeRemoveGroupEventDialog();
      } catch (error) {
        this.showSnackbar(error);
      }
    },
    closeRemoveGroupEventDialog() {
      this.eventId = null;
      this.groupId = null;
      this.removeGroupEventDialogVisible = false;
    },

    getDayName(date) {
      return getDayName(date);
    },
    addTime(startTime, durationInMinutes) {
      return addTime(startTime, durationInMinutes);
    },
    formatDate(date, format) {
      return formatDate(date, format);
    },
    addDay(date, days) {
      return addDay(date, days);
    },
    subtractDay(date, days) {
      return subtractDay(date, days);
    },
    filterByUser() {
      this.$store.dispatch('event/setEventFilterTerm', this.selectedUserId, { root: true });
      this.$store.dispatch('eventGroup/setEventGroupFilterTerm', this.selectedUserId, { root: true });
    },
    capitalizeFirstLetter(str) {
      return capitalizeFirstLetter(str);
    },
  },
  watch: {
    dots: {
      handler(newValue, oldValue) {
        // select day that is not in the past and has an event
        if (!newValue) return;

        const dates = Object.keys(newValue);
        if (dates.length === 0) return;

        const today = getDateNow();

        const filteredDates = dates
          .sort()
          .filter(date => isAfterOrSameDate(date, today));

        if (filteredDates.length === 0) return;
        if (this.datepicker !== filteredDates[0]) return;
        this.datepicker = filteredDates[0];
      },
      immediate: true,
    }
  }
};
</script>
<style>
.date-picker {
  box-shadow: none;
  min-height: 375px;
}
.v-date-picker-table {
  height: unset;
}
.v-date-picker-table--date td {
  padding: 5px 0;
}

.container--slim .v-expansion-panel-content__wrap {
  padding: 0 8px 16px !important;
}
.container--slim .v-expansion-panels--inset > .v-expansion-panel--active {
  max-width: 100% !important;
}
.date-time-string {
  font-size: 1.25rem;
}
</style>
