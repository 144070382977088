import { ActionContext, ActionTree } from 'vuex';
import { collection, orderBy, query, QuerySnapshot, where } from 'firebase/firestore';
import { listener } from '@/services/firebase';
import { RoleState } from './types';
import { RootState } from '@/store/types';
import { db } from '@/main';
import { readAllFromIndexedDB, writeToIndexedDB } from '@/services/indexedDB';

let unsubscribeVar: any;

const fetchRoles = async (context: ActionContext<RoleState, RootState>): Promise<void> => {
  const collectionRef = collection(db, 'roles');

  const callback = async (snapshot: QuerySnapshot) => {
    if (snapshot.docs.length === 0) {
      context.commit('setRolesLoading', false);
      return;
    }

    snapshot.docs.forEach(async doc => {
      await writeToIndexedDB('roles', { id: doc.id, ...doc.data() });
    });
    const { data, lastUpdateTime } = await readAllFromIndexedDB('roles');
    context.commit('addRoles', data);
    context.commit('setRolesLoading', false);
    unsubscribeVar();
    unsubscribeVar = listener(query(collectionRef, where('updateTime', '>', lastUpdateTime), orderBy('updateTime')), callback);
  };

  unsubscribeVar = listener(query(collectionRef, orderBy('updateTime')), callback);
};

const unsubscribe = () => {
  unsubscribeVar();
};

const actions: ActionTree<RoleState, RootState> = {
  fetchRoles,
  unsubscribe,
};

export default actions;
