import { Module } from 'vuex';
import { InstrumentState } from './types';
import { RootState } from '@/store/types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const instrumentStore: Module<InstrumentState, RootState> = {
  namespaced: true,
  state: {
    instruments: [],
    instrumentsLoading: false,
  },
  actions,
  mutations,
  getters
};

export default instrumentStore;
