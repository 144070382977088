import { GetterTree } from 'vuex';
import { AddressState } from './types';
import { RootState } from '@/store/types';
import { Address } from 'types';

const addressSearchFields = ['name', 'city', 'address'];

const orderedAddresses = (state: AddressState) => {
  return state.addresses?.sort((a, b) => a.name?.localeCompare(b.name)) ?? [];
};

const undeletedAddresses = (state: AddressState, getters: any) => {
  return getters.orderedAddresses.filter((address: Address) => !address.deleted);
};

const filteredAddresses = (state: AddressState, getters: any) => {
  return getters.undeletedAddresses.filter((address: Address) => {
    if (!state.addressSearchTerm) return address;
    return addressSearchFields.some(key => {
      if (!address[key as keyof Address]) return false;
      const string = address[key as keyof Address]?.toString().toLowerCase();
      if (!string?.length) return false;
      return string.includes(state.addressSearchTerm!);
    });
  });
};

const addresses = (state: AddressState, getters: any) => {
  return getters.filteredAddresses ?? [];
};

const addressesSubset = (state: AddressState, getters: any) => (page: number) => {
  const start = (page - 1) * 10;
  const stop = start + 10;
  return getters.filteredAddresses?.slice(start, stop) ?? [];
};

const addressCount = (state: AddressState, getters: any) => {
  return getters.filteredAddresses?.length ?? 0;
};

const addressLoading = (state: AddressState) => {
  return state.addressLoading;
};

const addressById = (state: AddressState) => (id: string) => {
  return state.addresses.find((address: Address) => address.id === id);
};

const getters: GetterTree<AddressState, RootState> = {
  orderedAddresses,
  undeletedAddresses,
  filteredAddresses,
  addresses,
  addressesSubset,
  addressCount,
  addressLoading,
  addressById,
};

export default getters;
