import { MutationTree } from 'vuex';
import { RoleState } from './types';
import { Role } from 'types';

const addRoles = (state: RoleState, payload: Role[]) => state.roles = payload;

const setRolesLoading = (state: RoleState, payload: boolean) => state.rolesLoading = payload;

const mutations: MutationTree<RoleState> = {
  addRoles,
  setRolesLoading
};

export default mutations;
