import { MutationTree } from 'vuex';
import { AddressState } from './types';
import { Address } from 'types';

const addAddresses = (state: AddressState, payload: Address[]) => state.addresses = payload;

const setAddressSearchTerm = (state: AddressState, payload: string) => state.addressSearchTerm = payload?.toLowerCase() ?? null;

const setAddressLoading = (state: AddressState, payload: boolean) => state.addressLoading = payload;

const mutations: MutationTree<AddressState> = {
  addAddresses,
  setAddressSearchTerm,
  setAddressLoading,
};

export default mutations;
