import { Module } from 'vuex';
import { AddressState } from './types';
import { RootState } from '@/store/types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const addressStore: Module<AddressState, RootState> = {
  namespaced: true,
  state: {
    addresses: [],
    addressSearchTerm: '',
    addressLoading: false,
  },
  actions,
  mutations,
  getters,
};

export default addressStore;
