import { functions } from '../main';
import { httpsCallable } from 'firebase/functions';
import { Log } from 'types';

export const log = async (payload: Log): Promise<any> => {
  const logUserSessionEvent = httpsCallable(functions, 'logUserSessionEvent');
  try {
    await logUserSessionEvent(payload);
  } catch (error) {
    console.log('Error logging event', error);
  }
};
