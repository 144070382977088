import { Module } from 'vuex';
import { MusicEventState } from './types';
import { RootState } from '@/store/types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const eventStore: Module<MusicEventState, RootState> = {
  namespaced: true,
  state: {
    events: [],
    eventsLoading: false,
    selectedUserId: null,
  },
  actions,
  mutations,
  getters,
};

export default eventStore;
