import { MutationTree } from 'vuex';
import { EventTypeState } from './types';
import { EventType } from 'types';

const addEventTypes = (state: EventTypeState, payload: EventType[]) => state.eventTypes = payload;

const setEventTypesLoading = (state: EventTypeState, payload: boolean) => state.eventTypesLoading = payload;

const mutations: MutationTree<EventTypeState> = {
  addEventTypes,
  setEventTypesLoading
};

export default mutations;
