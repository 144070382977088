import { GetterTree } from 'vuex';
import { CoreState } from './types';
import { RootState } from '@/store/types';
import { getDatesInRange, isBetweenDates } from '@/helpers/datetime';
import { AuthUser, MusicEvent, MusicTour, Participant, User } from 'types';
import { DateTime } from 'luxon';
import { sortBy } from 'lodash';

const init = (state: CoreState) => (state.init);

const drawerVisible = (state: CoreState) => (state.drawerVisible);

const snackbar = (state: CoreState) => (state.snackbar);

const dots = (state: CoreState, getters: any, rootState: RootState, rootGetters: any) => {
  const dots: { [date: string]: string } = {};
  const authUser: AuthUser = rootGetters['user/authUser'];
  const groups: MusicTour[] = rootGetters['eventGroup/groups'];
  const events: MusicEvent[] = rootGetters['event/events'];
  const selectedUserId = rootGetters['event/selectedUserId'];
  let eventsAssignedToGroup: MusicEvent[] = [];
  if (authUser) {
    if (groups && groups.length > 0) {
      groups
        .filter(group => !group.deleted)
        .forEach(group => {
          const events = group.events as MusicEvent[];
          eventsAssignedToGroup = [...eventsAssignedToGroup, ...events];

          const participants = group.participants;

          /**
           * Ha kivalasztunk egy adott eloadot a naptar feletti listabol, akkor
           * csak az o turnejait vagy eloadasait jelenitjuk meg a naptarban.
           */
          if (
            selectedUserId &&
            !participants.some(participant => participant.userId === selectedUserId)
          ) {
            return;
          }

          const tourUnconfirmed = participants.some(participant => !participant.confirmed);
          let userIsPerformerOfTheTour = false;
          let selectedUserIsPerformerOfTheTour = false;
          let userConfirmedTheTour = false;
          let selectedUserConfirmedTheTour = false;
          if (authUser.userRoles.some(role => ['performer', 'musician'].includes(role))) {
            const index = participants.findIndex(participant => participant.userId === authUser.uid);
            userIsPerformerOfTheTour = index !== -1;
            userConfirmedTheTour = userIsPerformerOfTheTour ? group.participants[index].confirmed : false;
          } else if (authUser.userRoles.every(role => !['performer', 'musician'].includes(role)) && selectedUserId) {
            const index = participants.findIndex(participant => participant.userId === selectedUserId);
            selectedUserIsPerformerOfTheTour = index !== -1;
            selectedUserConfirmedTheTour = selectedUserIsPerformerOfTheTour
              ? group.participants[index].confirmed
              : false;
          }

          const dates = getDatesInRange(
            group.startDate,
            group.endDate ? group.endDate : group.startDate
          );
          dates.forEach(date => {
            if (authUser.userRoles.some(role => ['performer', 'musician'].includes(role)) && !userIsPerformerOfTheTour) {
              return;
            }
            if (!participants || participants.length == 0) {
              dots[date] = 'red';
              return;
            }
            const color = Object.prototype.hasOwnProperty.call(dots, date) ? dots[date] : 'green';
            if (color == 'green') {
              if (authUser.userRoles.every(role => !['performer', 'musician'].includes(role)) && selectedUserId) {
                dots[date] = selectedUserConfirmedTheTour ? 'green' : 'red';
              } else if (authUser.userRoles.every(role => !['performer', 'musician'].includes(role)) && !selectedUserId) {
                dots[date] = tourUnconfirmed ? 'red' : 'green';
              } else if (userIsPerformerOfTheTour) {
                dots[date] = userConfirmedTheTour ? 'green' : 'red';
              }
            }
          });
        });
    }
  }
  return dots;
};

const statByMonth = (state: CoreState, getters: any, rootState: RootState, rootGetters: any) => (month: string) => {
  const data: {
    [userId: string]: {
      userId: string;
      userFullName: string;
      numConfirmedEvents: number;
      numUnconfirmedEvents: number;
      kms: number
    }
  } = {};
  const authUser: AuthUser = rootGetters['user/authUser'];
  const users: User[] = rootGetters['user/filteredUsersBySearchTerm'];
  const groups: MusicTour[] = rootGetters['eventGroup/groups'];
  const events: MusicEvent[] = rootGetters['event/events'];

  if (authUser && users && (groups?.length > 0 || events?.length > 0)) {
    const date = DateTime.fromFormat(month, 'yyyy-MM');
    const firstDayOfSelectedMonth = date.startOf('month').toFormat('yyyy-MM-dd');
    const lastDayOfSelectedMonth = date.endOf('month').toFormat('yyyy-MM-dd');

    const countedGroups: string[] = [];
    const filteredEvents = events?.filter(event => {
      if (event.deleted) return false;
      return event.date >= firstDayOfSelectedMonth && event.date <= lastDayOfSelectedMonth;
    });

    filteredEvents?.forEach(event => {
      const group = groups?.find(group => {
        if (group.deleted) return false;
        const events = group.events as string[];
        return events.includes(event.id);
      });

      const isEventAssignedToGroup = !!group;
      let participants, distance = 0;
      if (isEventAssignedToGroup) {
        participants = group.participants;
        distance = Number(group.distance);
      } else {
        participants = event.participants;
        distance = Number(event.distance);
      }

      participants?.forEach(participant => {
        const userId = participant.userId;
        const isDriver = participant.isDriver;
        const user = users?.find(user => user.id === userId);
        const firstName = user?.firstname;
        const lastName = user?.lastname;
        const userFullName = (lastName ?? '') + (lastName ? ' ' : '') + (firstName ?? '');
        const confirmed = participant.confirmed;
        // ha a felhasznalo eloado, csak az o adatait dolgozzuk fel
        if (authUser.userRoles.some(role => ['performer', 'musician'].includes(role)) && authUser.uid !== userId) {
          return;
        }
        if (!Object.prototype.hasOwnProperty.call(data, userId)) {
          data[userId] = {
            userId,
            userFullName,
            numConfirmedEvents: 0,
            numUnconfirmedEvents: 0,
            kms: 0,
          };
        }
        if (confirmed) data[userId].numConfirmedEvents += 1;
        else data[userId].numUnconfirmedEvents += 1;

        if (isDriver && group && !countedGroups.includes(group.id)) {
          data[userId].kms += distance;
        }
      });

      if (isEventAssignedToGroup && !countedGroups.includes(group.id)) {
        countedGroups.push(group.id);
      }
    });
  }
  return Object.values(data).sort((a, b) => a.userFullName.localeCompare(b.userFullName));
};

const statByMonthAsCSV = (state: CoreState, getters: any, rootState: RootState, rootGetters: any) => (month: string) => {
  const data: { [key: string]: any } = {};
  const users: User[] = rootGetters['user/filteredUsersBySearchTerm'];
  const groups: MusicTour[] = rootGetters['eventGroup/groups'];
  const events: MusicEvent[] = rootGetters['event/events'];
  if (users && (groups?.length > 0 || events?.length > 0)) {
    const date = DateTime.fromFormat(month, 'yyyy-MM');
    const firstDayOfSelectedMonth = date.startOf('month').toFormat('yyyy-MM-dd');
    const lastDayOfSelectedMonth = date.endOf('month').toFormat('yyyy-MM-dd');

    const countedGroups: any = [];
    const filteredEvents = events?.filter(event => {
      return (
        !event.deleted && isBetweenDates(event.date, firstDayOfSelectedMonth, lastDayOfSelectedMonth)
      );
    });

    filteredEvents?.forEach(event => {
      const group = groups?.find(group => {
        if (group.deleted) return false;
        const events = group.events as string[];
        return events.includes(event.id);
      });
      const isEventAssignedToGroup = !!group;
      let participants, distance = 0;
      if (isEventAssignedToGroup) {
        participants = group.participants;
        distance = Number(group.distance);
      } else {
        participants = event.participants;
        distance = Number(event.distance);
      }

      participants.forEach((participant: Participant) => {
        const userId = participant.userId;
        const isDriver = participant.isDriver;
        const user = users?.find((user: User) => user.id === userId);
        const firstName = user?.firstname;
        const lastName = user?.lastname;
        const userFullName = (lastName ?? '') + (lastName ? ' ' : '') + (firstName ?? '');
        const confirmed = participant.confirmed;
        if (!Object.prototype.hasOwnProperty.call(data, userId)) {
          data[userId] = {
            userFullName,
            numConfirmedEvents: 0,
            numUnconfirmedEvents: 0,
            kms: 0,
          };
        }
        if (confirmed) data[userId].numConfirmedEvents += 1;
        else data[userId].numUnconfirmedEvents += 1;

        if (isDriver && group && !countedGroups.includes(group.id)) {
          data[userId].kms += distance;
        }
      });

      if (isEventAssignedToGroup && !countedGroups.includes(group.id)) {
        countedGroups.push(group.id);
      }
    });
  }
  const header = [['név', 'előadások (db)', 'előadások - nem elfogadott (db)', 'autóhasználat (km)']];
  const rows = Object.values(sortBy(data, ['userFullName'])).map(item => {
    return [item.userFullName, item.numConfirmedEvents, item.numUnconfirmedEvents, item.kms];
  });
  return [...header, ...rows];
};

const eventsByMonthAsCSV = (state: CoreState, getters: any, rootState: RootState, rootGetters: any) => (month: string) => {
  let rows: any = [];
  const users: User[] = rootGetters['user/filteredUsersBySearchTerm'];
  const groups: MusicTour[] = rootGetters['eventGroup/groups'];
  const events: MusicEvent[] = rootGetters['event/events'];
  if (users && (groups?.length > 0 || events?.length > 0)) {
    const date = DateTime.fromFormat(month, 'yyyy-MM');
    const firstDayOfSelectedMonth = date.startOf('month').toFormat('yyyy-MM-dd');
    const lastDayOfSelectedMonth = date.endOf('month').toFormat('yyyy-MM-dd');

    const filteredEvents = events?.filter(event => {
      return (
        !event.deleted && isBetweenDates(event.date, firstDayOfSelectedMonth, lastDayOfSelectedMonth)
      );
    });

    filteredEvents.forEach(event => {
      const group = groups?.find(group => {
        if (group.deleted) return false;
        const events = group.events as string[];
        return events.includes(event.id);
      });
      const isEventAssignedToGroup = !!group;
      let participants, distance;
      if (isEventAssignedToGroup) {
        participants = group.participants;
        distance = group.distance;
      } else {
        participants = event.participants;
        distance = event.distance;
      }
      const address = rootGetters['address/addressById'](event.addressId);

      const p = participants
        .map((participant: Participant) => {
          const id = participant.userId;
          const user = users?.find(user => user.id === id);
          return user?.lastname + ' ' + user?.firstname;
        })
        .join(' / ');

      rows.push({
        date: event.date,
        time: event.startTime,
        address: address.name.replace(',', ''),
        participants: p,
        tourName: isEventAssignedToGroup ? group.name : '',
        distance,
      });
    });
  }
  const header = [['dátum', 'kezdés', 'helyszín', 'előadók', 'turné neve', 'úthossz']];
  rows = rows
    .sort(function (a: any, b: any) {
      const keyA = a.date + a.time;
      const keyB = b.date + b.time;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    })
    .map((item: any) => {
      return [item.date, item.time, item.address, item.participants, item.tourName, item.distance];
    });
  return [...header, ...rows];
};

const getters: GetterTree<CoreState, RootState> = {
  init,
  drawerVisible,
  snackbar,
  dots,
  statByMonth,
  statByMonthAsCSV,
  eventsByMonthAsCSV,
};

export default getters;
