import { Module } from 'vuex';
import { UserState } from './types';
import { RootState } from '@/store/types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const userStore: Module<UserState, RootState> = {
  namespaced: true,
  state: {
    authUser: null,
    userRoles: [],
    users: [],
    usersLoading: false,
    userSearchTerm: null,
  },
  actions,
  mutations,
  getters,
};

export default userStore;
