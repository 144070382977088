<template>
  <div class="datepicker">
    <div class="datepicker__header">
      <v-btn
        density="comfortable"
        variant="plain"
        icon="mdi-chevron-left"
        @click="prevMonth"
      />
      <div class="datepicker__header__text">{{ currentYear }} {{ currentMonthName }}</div>
      <v-btn
        density="comfortable"
        variant="plain"
        icon="mdi-chevron-right"
        @click="nextMonth"
      />
    </div>
    <div class="datepicker__body days">
      <div v-for="(day, index) in daysLabels" :key="index" class="day text-center">{{ day }}</div>
      <template v-for="(day, index) in days" :key="index">
        <v-btn
          density="comfortable"
          variant="plain"
          class="day"
          :icon="`mdi-numeric-${day}`"
          :class="{ weekend: isWeekend(day) }"
          @click="selectDate(day)"
          v-if="day">
          {{ day }}
          <div class="events" v-if="getEvent(day)">
            <div class="lighten-1" :class="getEvent(day)" />
          </div>
        </v-btn>
        <div v-else></div>
      </template>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'DatePicker',
  data() {
    return {
      selectedDate: DateTime.local(),
      daysLabels: ['H', 'K', 'Sze', 'Cs', 'P', 'Szo', 'V'],
      monthLabels: [
        'Január', 'Február', 'Március', 'Április', 'Május', 'Június',
        'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'
      ],
    };
  },
  props: {
    modelValue: {
      type: String,
      default: DateTime.local(),
    },
    events: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    currentMonthName() {
      return this.monthLabels[this.selectedDate.month - 1];
    },
    currentMonth() {
      return this.selectedDate.month;
    },
    currentYear() {
      return this.selectedDate.year;
    },
    days() {
      const daysInMonth = this.selectedDate.daysInMonth;
      const firstDayOfMonth = DateTime.local(this.currentYear, this.currentMonth, 1).weekday;
      const emptyDays = Array.from({ length: firstDayOfMonth - 1 }, () => null);
      const monthDays = Array.from({ length: daysInMonth }, (_, i) => i + 1);
      return [...emptyDays, ...monthDays];
    },
  },
  methods: {
    isWeekend(date) {
      const day = DateTime.local(this.currentYear, this.currentMonth, date).weekday;
      return day === 6 || day === 7;
    },
    getEvent(date) {
      if (!this.events) return;
      return this.events[`${this.currentYear}-${this.currentMonth.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`];
    },
    selectDate(date) {
      this.$emit(
        'update:modelValue',
        `${this.currentYear}-${this.currentMonth.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`,
      );
    },
    prevMonth() {
      this.selectedDate = this.selectedDate.minus({ months: 1 }).startOf('month');
    },
    nextMonth() {
      this.selectedDate = this.selectedDate.plus({ months: 1 }).startOf('month');
    },
  },
  mounted() {
    this.selectedDate = DateTime.fromISO(this.modelValue);
  },
};
</script>

<style lang="scss">
.datepicker {
    height: auto;
    overflow: hidden;
    position: relative;
    z-index: 0;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:0 auto;
    width: 100%;

    &__header {
      padding: 4px 16px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      position:relative;
      margin-bottom: 1rem;

      &__text {
        flex: 1;
        text-align: center;
        position: relative;
        overflow: hidden;
      }
    }

    &__body {
      position: relative;
      padding: 0 12px;
      height:242px;

      .v-btn {
        z-index: auto;
        margin: 0;
        font-size: 12px;
      }
    }

    & > div {
        width:100%;
    }

  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    .day {
      position: relative;
      font-size: 0.75rem;

      &.weekend {
        color: gray;
      }
    }
  }

  .events {
    height: 8px;
    width: 100%;
    position: absolute;
    left: 0;
    text-align: center;
    white-space: pre;
    bottom: 6px;

    & > div {
      border-radius: 50%;
      display: inline-block;
      height: 8px;
      width: 8px;
      margin: 0 1px;
    }

    .green.lighten-1 {
      background-color: #66bb6a!important;
      border-color: #66bb6a!important;
    }

    .red.lighten-1 {
      background-color: #ef5350!important;
      border-color: #ef5350!important;
    }
  }
}
</style>
