import { ActionContext, ActionTree } from 'vuex';
import { getIdTokenResult, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, UserCredential } from 'firebase/auth';
import { collection, orderBy, query, QuerySnapshot, where } from 'firebase/firestore';
import { listener } from '@/services/firebase';
import { UserState } from './types';
import { RootState } from '@/store/types';
import { auth, db } from '@/main';
import { readAllFromIndexedDB, writeToIndexedDB } from '@/services/indexedDB';

let unsubscribe1: any, unsubscribe2: any;

const signin = async (
  context: ActionContext<UserState, RootState>,
  payload: { email: string; password: string }
): Promise<UserCredential> => {
  try {
    const response = await signInWithEmailAndPassword(auth, payload.email, payload.password);
    const { uid, email } = response.user;
    context.commit('user/setUser', { uid, email, userRoles: null, loginError: null }, { root: true });
    context.dispatch('setUserRole', null);
    return response;
  } catch (error: any) {
    context.commit('user/setUser', { uid: null, email: null, loginError: error.code }, { root: true });
    throw error;
  }
};

const resetPassword = async (
  context: ActionContext<UserState, RootState>,
  payload: { email: string }
): Promise<void> => {
  // eslint-disable-next-line no-useless-catch
  try {
    await sendPasswordResetEmail(auth, payload.email);
  } catch (error) {
    throw error;
  }
};

const resetUser = (context: ActionContext<UserState, RootState>): void => {
  context.commit('user/setUser', null, { root: true });
};

const autoSignin = (
  context: ActionContext<UserState, RootState>,
  payload: { uid: string; email: string }
): void => {
  context.commit('user/setUser', { uid: payload.uid, email: payload.email, userRoles: null, loginError: null }, { root: true });
  context.dispatch('setUserRole', null);
};

const signout = async (context: ActionContext<UserState, RootState>): Promise<void> => {
  await signOut(auth);
  context.dispatch('address/unsubscribe', null, { root: true });
  context.dispatch('event/unsubscribe', null, { root: true });
  context.dispatch('eventGroup/unsubscribe', null, { root: true });
  context.dispatch('eventType/unsubscribe', null, { root: true });
  context.dispatch('instrument/unsubscribe', null, { root: true });
  context.dispatch('role/unsubscribe', null, { root: true });
  context.dispatch('schoolDistrict/unsubscribe', null, { root: true });
  context.dispatch('user/unsubscribe', null, { root: true });
  context.commit('user/setUser', null, { root: true });
};

const fetchUsers = async (context: ActionContext<UserState, RootState>): Promise<void> => {
  context.commit('setUsersLoading', true);

  const { data: initialData, lastUpdateTime: initialLastUpdateTime } = await readAllFromIndexedDB('users');
  context.commit('addUsers', initialData);

  const collectionRef = collection(db, 'users');

  const callback = async (snapshot: QuerySnapshot) => {
    console.log('snap/usr', snapshot.docs.length);
    if (snapshot.docs.length === 0) {
      context.commit('setUsersLoading', false);
      return;
    }

    snapshot.docs.forEach(async doc => {
      await writeToIndexedDB('users', { id: doc.id, ...doc.data() });
    });
    const { data, lastUpdateTime } = await readAllFromIndexedDB('users');
    context.commit('addUsers', data);
    context.commit('setUsersLoading', false);
    unsubscribe1();
    unsubscribe1 = listener(query(collectionRef, where('updateTime', '>', lastUpdateTime)), callback);
  };

  unsubscribe1 = listener(
    initialLastUpdateTime
      ? query(collectionRef, where('updateTime', '>', initialLastUpdateTime), orderBy('updateTime'))
      : query(collectionRef),
    callback
  );
};

const setUserSearchTerm = (context: ActionContext<UserState, RootState>, payload: string): void =>{
  context.commit('setUserSearchTerm', payload);
};

const fetchUserRoles = async (context: ActionContext<UserState, RootState>): Promise<void> => {
  const { data: initialData, lastUpdateTime: initialLastUpdateTime } = await readAllFromIndexedDB('userRoles');
  context.commit('addUserRoles', initialData);

  const collectionRef = collection(db, 'userRoles');

  const callback = async (snapshot: QuerySnapshot) => {
    console.log('snap/urol', snapshot.docs.length);
    if (snapshot.docs.length === 0) {
      return;
    }

    snapshot.docs.forEach(async doc => {
      await writeToIndexedDB('userRoles', { id: doc.id, ...doc.data() });
    });
    const { data, lastUpdateTime } = await readAllFromIndexedDB('userRoles');
    context.commit('addUserRoles', data);
    unsubscribe2();
    unsubscribe2 = listener(
      lastUpdateTime
        ? query(collectionRef, where('updateTime', '>', lastUpdateTime), orderBy('updateTime'))
        : query(collectionRef, orderBy('updateTime')),
      callback
    );
  };

  unsubscribe2 = listener(
    initialLastUpdateTime
      ? query(collectionRef, where('updateTime', '>', initialLastUpdateTime), orderBy('updateTime'))
      : query(collectionRef, orderBy('updateTime')),
    callback
  );
};

const setUserRole = async (context: ActionContext<UserState, RootState>): Promise<void> => {
  try {
    const result = await getIdTokenResult(auth.currentUser!);
    if (result.claims && result.claims.roles) {
      context.commit('setAuthUserRoles', result.claims.roles);
    }
  } catch (error) {
    console.log(error);
  }
};

const unsubscribe = () =>{
  unsubscribe1();
  unsubscribe2();
};

const actions: ActionTree<UserState, RootState> = {
  signin,
  resetPassword,
  resetUser,
  autoSignin,
  signout,
  fetchUsers,
  setUserSearchTerm,
  fetchUserRoles,
  setUserRole,
  unsubscribe,
};

export default actions;
