<template>
  <div>
    <v-app-bar flat color="blue-darken-3">
      <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">{{ $t('instruments') }}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click.stop="dialogVisible = true">
        <v-icon icon="mdi-plus" />
      </v-btn>
    </v-app-bar>

    <v-container>
      <v-skeleton-loader type="list-item-two-line@10" v-if="loadingState" />
      <v-list v-else-if="instruments.length > 0">
        <slot name="item" v-for="item in instruments">
          <v-list-item @click="edit(item.id)">
            <v-list-item-title class="valign-center">
              <v-avatar start size="32" class="bg-grey-lighten-3 mr-3" v-if="item.initials">{{ item.initials }}</v-avatar>
              <span>{{ item.name }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
        </slot>
      </v-list>
      <v-list v-else>
        <v-list-item disabled>
          <v-list-item-title class="list-item-title">{{ $t('no-results') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-container>

    <v-instrument-dialog
      v-if="dialogVisible"
      :visible="dialogVisible"
      :id="id"
      @close="closeDialog"
    />
  </div>
</template>
<script>
import InstrumentDialog from '@/components/dialog/InstrumentDialog.vue';

export default {
  name: 'InstrumentsView',
  data: () => ({
    id: null,
    dialogVisible: false,
  }),
  components: {
    'v-instrument-dialog': InstrumentDialog,
  },
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
    userCanEdit() {
      return this.user && ['admin', 'editor'].some(role => this.user?.userRoles?.includes(role));
    },
    instruments() {
      return this.$store.getters['instrument/instruments'];
    },
    loadingState() {
      return this.$store.getters['loadingState'];
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch('core/toggleDrawer', null);
    },
    edit(id) {
      if (this.userCanEdit) {
        this.id = id;
        this.dialogVisible = true;
      }
    },
    closeDialog() {
      this.id = null;
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
.confirm-text {
  font-size: 1rem;
}
</style>
