<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" sm="8" md="4">
        <p>{{ $t('requested-page-can-not-be-found') }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'PageNotFoundView',
};
</script>
