<template>
  <div class="app-menu">
    <v-list v-model:selected="activeMenuItem">
      <template v-for="(item, index) in menuItems" :key="item.name">
        <v-list-item
          link
          color="blue-darken-2"
          :value="item.name"
          @click.prevent="router.push(item.link)"
          v-if="isListItemVisible(item)"
        >
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
          <v-list-item-title>{{ index === 0 ? item.title : $t(item.title) }}</v-list-item-title>
        </v-list-item>
        <v-divider class="my-2" v-if="item.dividerAfter"></v-divider>
      </template>
    </v-list>

    <v-divider />

    <v-list>
      <v-list-item link @click.prevent="signout">
        <template v-slot:prepend>
          <v-icon icon="mdi-logout-variant"></v-icon>
        </template>
        <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import items from './menu-items';
import { log } from '@/helpers/logger';

const router = useRouter();

const menuItems = ref(items);
const activeMenuItem = ref([]);

// Update the title of the first menu item with the user's first name
const store = useStore();
const authUser = computed(() => store.getters['user/authUser']);
const userInfo = computed(() => store.getters['user/userInfo']);
watch(userInfo, userInfo => {
  if (userInfo?.firstname) {
    menuItems.value[0].title = userInfo.firstname;
  }
});

// Set the active menu item based on the current route
watch(() => router.currentRoute.value.name, name => {
  activeMenuItem.value = [name];
});

const isListItemVisible = item => {
  return item.permission.some(permittedRole => authUser.value.userRoles?.includes(permittedRole));
};

const signout = () => {
  log({ type: 'logout', userId: authUser.value.uid, email: authUser.value.email });
  store.dispatch('user/signout');
  router.push({ name: 'login' });
  activeMenuItem.value = [1];
};
</script>
