import { GetterTree } from 'vuex';
import { SchoolDistrictState } from './types';
import { RootState } from '@/store/types';

const orderedSchoolDistricts = (state: SchoolDistrictState) => {
  return state.schoolDistricts?.sort((a, b) => a.name?.localeCompare(b.name)) ?? [];
};

const schoolDistricts = (state: SchoolDistrictState, getters: any) => {
  return getters.orderedSchoolDistricts.filter((item: any) => !item.deleted).map((sd: any) => {
    const { name, id } = sd;
    return { name, id };
  });
};

const schoolDistrictById = (state: SchoolDistrictState, getters: any) => (id: string) => {
  return getters.orderedSchoolDistricts.find((item: any) => item.id === id);
};

const schoolDistrictsInclDeleted = (state: SchoolDistrictState, getters: any) => {
  return getters.orderedSchoolDistricts;
};

const schoolDistrictsLoading = (state: SchoolDistrictState) => {
  return state.schoolDistrictsLoading;
};

const getters: GetterTree<SchoolDistrictState, RootState> = {
  orderedSchoolDistricts,
  schoolDistricts,
  schoolDistrictById,
  schoolDistrictsInclDeleted,
  schoolDistrictsLoading,
};

export default getters;
