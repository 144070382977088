import { MutationTree } from 'vuex';
import { EventGroupState } from './types';
import { EventGroup } from 'types';

const addEventGroups = (state: EventGroupState, payload: EventGroup[]) => {
  state.eventGroups = payload;
};

const setEventGroupsLoading = (state: EventGroupState, payload: boolean) => {
  state.eventGroupsLoading = payload;
};

const setEventGroupFilterTerm = (state: EventGroupState, payload: string) => {
  if (payload) {
    if (payload === '0') {
      state.selectedUserId = null;
    } else {
      state.selectedUserId = payload;
    }
  }
};

const mutations: MutationTree<EventGroupState> = {
  addEventGroups,
  setEventGroupsLoading,
  setEventGroupFilterTerm,
};

export default mutations;
