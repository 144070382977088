import { ActionContext, ActionTree } from 'vuex';
import { collection, orderBy, query, QuerySnapshot, where } from 'firebase/firestore';
import { listener } from '@/services/firebase';
import { MusicEventState } from './types';
import { RootState } from '@/store/types';
import { db } from '@/main';
import { readAllFromIndexedDB, writeToIndexedDB } from '@/services/indexedDB';

let unsubscribeVar: any;

const fetchEvents = async (context: ActionContext<MusicEventState, RootState>): Promise<void> => {
  context.commit('setEventsLoading', true);

  const { data: initialData, lastUpdateTime: initialLastUpdateTime } = await readAllFromIndexedDB('events');
  context.commit('addEvents', initialData);

  const collectionRef = collection(db, 'events');

  const callback = async (snapshot: QuerySnapshot) => {
    console.log('snap/evt', snapshot.docs.length);
    if (snapshot.docs.length === 0) {
      context.commit('setEventsLoading', false);
      return;
    }

    snapshot.docs.forEach(async doc => {
      await writeToIndexedDB('events', { id: doc.id, ...doc.data() });
    });
    const { data, lastUpdateTime } = await readAllFromIndexedDB('events');
    context.commit('addEvents', data);
    context.commit('setEventsLoading', false);
    unsubscribeVar();
    unsubscribeVar = listener(query(collectionRef, where('updateTime', '>', lastUpdateTime), orderBy('updateTime')), callback);
  };

  unsubscribeVar = listener(
    initialLastUpdateTime
      ? query(collectionRef, where('updateTime', '>', initialLastUpdateTime), orderBy('updateTime'))
      : query(collectionRef, orderBy('updateTime')),
    callback
  );
};

const setEventFilterTerm = (context: ActionContext<MusicEventState, RootState>, payload: string): void => {
  context.commit('setEventFilterTerm', payload);
};

const unsubscribe = (): void => {
  unsubscribeVar();
};

const actions: ActionTree<MusicEventState, RootState> = {
  fetchEvents,
  setEventFilterTerm,
  unsubscribe,
};

export default actions;
