<template>
  <div>
    <v-app-bar flat color="blue-darken-3">
      <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">{{ $t('school-districts') }}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click.stop="dialogVisible = true">
        <v-icon icon="mdi-plus" />
      </v-btn>
    </v-app-bar>

    <v-container>
      <v-skeleton-loader type="list-item-two-line@10" v-if="schoolDistrictsLoading" />
      <v-list v-else-if="schoolDistricts.length > 0">
        <slot v-for="item in schoolDistricts">
          <v-list-item @click="edit(item.id)">
            <v-list-item-title class="list-item-title">{{ item.name }}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
        </slot>
      </v-list>
      <v-list v-else>
        <v-list-item disabled>
          <v-list-item-title class="list-item-title">{{ $t('no-results') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-container>

    <v-school-district-dialog
      v-if="dialogVisible"
      :visible="dialogVisible"
      :id="id"
      @close="closeDialog"
    ></v-school-district-dialog>
  </div>
</template>
<script>
import SchoolDistrictDialog from '@/components/dialog/SchoolDistrictDialog.vue';
export default {
  name: 'SchoolDistrictsView',
  data: () => ({
    id: null,
    dialogVisible: false,
  }),
  components: {
    'v-school-district-dialog': SchoolDistrictDialog,
  },
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
    userCanEdit() {
      return this.user && ['admin', 'editor'].some(role => this.user?.userRoles?.includes(role));
    },
    schoolDistricts() {
      return this.$store.getters['schoolDistrict/schoolDistricts'];
    },
    schoolDistrictsLoading() {
      return this.$store.getters['schoolDistrict/schoolDistrictsLoading'];
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch('core/toggleDrawer', null);
    },
    edit(id) {
      if (this.userCanEdit) {
        this.id = id;
        this.dialogVisible = true;
      }
    },
    closeDialog() {
      this.id = null;
      this.dialogVisible = false;
    },
  },
};
</script>
