<template>
  <span class="ws-pl" v-if="role">{{ role.name }}</span>
</template>

<script setup>
import { computed, defineProps, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  roleId: String,
});

const store = useStore();
const roles = computed(() => store.getters['user/userRoles']);

const role = ref();
onBeforeMount(() => {
  if (!props.roleId) return;
  role.value = roles.value?.find(role => role.id === props.roleId);
});
</script>
