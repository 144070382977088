import { ActionContext, ActionTree } from 'vuex';
import { SchoolDistrictState } from './types';
import { RootState } from '@/store/types';
import { db } from '@/main';
import { collection, orderBy, query, QuerySnapshot, where } from 'firebase/firestore';
import { readAllFromIndexedDB, writeToIndexedDB } from '@/services/indexedDB';
import { listener } from '@/services/firebase';

let unsubscribeVar: any;

const fetchSchoolDistricts = async (context: ActionContext<SchoolDistrictState, RootState>) => {
  context.commit('setSchoolDistrictsLoading', true);

  const { data: initialData, lastUpdateTime } = await readAllFromIndexedDB('schoolDistricts');
  context.commit('addSchoolDistricts', initialData);

  const collectionRef = collection(db, 'schoolDistricts');

  const callback = async (snapshot: QuerySnapshot) => {
    console.log('snap/schd', snapshot.docs.length);
    if (snapshot.docs.length === 0) {
      context.commit('setSchoolDistrictsLoading', false);
      return;
    }

    snapshot.docs.forEach(async doc => {
      await writeToIndexedDB('schoolDistricts', { id: doc.id, ...doc.data() });
    });
    const { data, lastUpdateTime } = await readAllFromIndexedDB('schoolDistricts');
    context.commit('addSchoolDistricts', data);
    context.commit('setSchoolDistrictsLoading', false);
    unsubscribeVar();
    unsubscribeVar = listener(query(collectionRef, where('updateTime', '>', lastUpdateTime), orderBy('updateTime')), callback);
  };

  unsubscribeVar = listener(
    lastUpdateTime
      ? query(collectionRef, where('updateTime', '>', lastUpdateTime), orderBy('updateTime'))
      : query(collectionRef, orderBy('updateTime')),
    callback
  );
};

const unsubscribe = () => {
  unsubscribeVar();
};

const actions: ActionTree<SchoolDistrictState, RootState> = {
  fetchSchoolDistricts,
  unsubscribe,
};

export default actions;
