<template>
  <v-list-item class="participant-list-item" :class="{ 'participant-list-item--ok': props.participant?.confirmed }" v-if="user">
    <template v-slot:prepend>
    </template>

    <template v-slot:append>
      <v-btn color="grey-lighten-1" icon="mdi-information" variant="text" @click="isDialogVisible = true" />
    </template>

    <v-list-item-title class="ws-pl">{{ `${user.lastname} ${user.firstname}` }}</v-list-item-title>

    <v-list-item-subtitle class="participant-list-item__subtitle items-separated-by-dot">
      <InstrumentText :instrumentId="props.participant?.instrumentId" class="ws-pl" v-if="props.participant?.instrumentId" />
      <RoleText :roleId="props.participant?.roleId" class="ws-pl" v-else />
      <VIcon icon="mdi-car-side" v-if="props.participant?.isDriver" />
    </v-list-item-subtitle>

  </v-list-item>

  <ParticipantInfoDialog v-model="isDialogVisible" :user="user" v-if="user && isDialogVisible" />
</template>

<script lang="ts" setup>
import { computed, defineProps, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import InstrumentText from '@/components/InstrumentText.vue';
import RoleText from '@/components/RoleText.vue';
import ParticipantInfoDialog from '@/components/dialog/ParticipantInfoDialog.vue';
import { Participant, User } from 'types';

const props = defineProps({
  participant: Object as PropType<Participant>,
});

const store = useStore();
const users = computed<User[]>(() => store.getters['user/users']);
const user = computed(() => users.value?.find(user => user.id === props.participant?.userId));

const isDialogVisible = ref(false);
</script>

<style lang="scss">
.participant-list-item {
  border-left-color: rgba(var(--v-border-color), var(--v-border-opacity));
  border-left-width: 6px !important;

  &--ok {
    border-left-color: rgba(#66bb6a, 0.5) !important;
  }

  &__subtitle {
    display: flex;
    align-items: center;
  }
}
</style>
