import { MutationTree } from 'vuex';
import { MusicEventState } from './types';
import { MusicEvent } from 'types';

const addEvents = (state: MusicEventState, payload: MusicEvent[]) => state.events = payload;

const setEventsLoading = (state: MusicEventState, payload: boolean) => state.eventsLoading = payload;

const setEventFilterTerm = (state: MusicEventState, payload: string) => {
  if (payload) {
    if (payload === '0') {
      state.selectedUserId = null;
    } else {
      state.selectedUserId = payload;
    }
  }
};

const mutations: MutationTree<MusicEventState> = {
  addEvents,
  setEventsLoading,
  setEventFilterTerm,
};

export default mutations;
