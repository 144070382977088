import { Module } from 'vuex';
import { CoreState } from './types';
import { RootState } from '@/store/types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const coreStore: Module<CoreState, RootState> = {
  namespaced: true,
  state: {
    init: true,
    drawerVisible: true,
    snackbar: undefined
  },
  actions,
  mutations,
  getters,
};

export default coreStore;
