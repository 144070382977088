import { GetterTree } from 'vuex';
import { EventTypeState } from './types';
import { RootState } from '@/store/types';
import { EventType } from 'types';

const orderedEventTypes = (state: EventTypeState) => {
  return state.eventTypes?.sort((a, b) => a.name?.localeCompare(b.name)) ?? [];
};

const eventTypes = (state: EventTypeState, getters: any) => {
  return getters.orderedEventTypes.filter((item: EventType) => !item.deleted);
};

const eventTypeById = (state: EventTypeState, getters: any) => (id: string) => {
  return getters.orderedEventTypes.find((item: EventType) => item.id === id);
};

const eventTypesInclDeleted = (state: EventTypeState, getters: any) => {
  return getters.orderedEventTypes;
};

const eventTypesLoading = (state: EventTypeState) => {
  return state.eventTypesLoading;
};

const getters: GetterTree<EventTypeState, RootState> = {
  orderedEventTypes,
  eventTypes,
  eventTypeById,
  eventTypesInclDeleted,
  eventTypesLoading,
};

export default getters;
