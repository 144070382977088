import { GetterTree } from 'vuex';
import { UserState } from './types';
import { RootState } from '@/store/types';
import { User, UserRole, UserRoleId } from 'types';

const usersSearchFields: Array<keyof User> = ['lastname', 'firstname'];

const orderedUsers = (state: UserState) => {
  const users = [...state.users];
  return users.sort((a, b) => a.fullName?.localeCompare(b.fullName)) ?? [];
};

const users = (state: UserState, getters: any) => (getters.orderedUsers);

const usersExclDeleted = (state: UserState, getters: any) => (getters.orderedUsers.filter((user: User) => !user.deleted));

const usersWithParticipantRoles = (state: UserState, getters: any) => {
  return getters.orderedUsers
    .filter((user: User) => user.userRoles?.some((role: UserRoleId) => ['musician', 'performer'].includes(role)));
};

const usersWithParticipantRolesExclDeleted = (state: UserState, getters: any, rootState: RootState, rootGetters: any) => {
  const users = rootGetters['user/usersExclDeleted'];
  return users?.filter((user: User) => user.userRoles?.some((role: UserRoleId) => ['musician', 'performer'].includes(role)));
};

const authUser = (state: UserState) => (state.authUser);

const isAuthUserAnAdmin = (state: UserState) => (state.authUser?.userRoles?.includes('admin'));

const userInfo = (state: UserState, getters: any, rootState: RootState, rootGetters: any) => {
  return (
    state.users.length > 0 &&
    rootGetters['user/authUser'] &&
    rootGetters['user/authUser'].uid &&
    state.users.find((user: User) => user.id === rootGetters['user/authUser'].uid)
  );
};

const usersLoading = (state: UserState) => (state.usersLoading);

const filteredUsersBySearchTerm = (state: UserState, getters: any, rootState: RootState, rootGetters: any) => {
  const users = rootGetters['user/usersWithParticipantRolesExclDeleted'];
  return users?.filter((user: User) => {
    if (!state.userSearchTerm) return user;
    return usersSearchFields.some((key: keyof User) => {
      const string = user[key] ? user[key].toString().toLowerCase() : '';
      return string.length > 0 ? string.includes(state.userSearchTerm!) : false;
    });
  });
};

const userById = (state: UserState) => (id: string) => (state.users.find((item: User) => item.id === id));

const usersSubset = (state: UserState, getters: any, rootState: RootState, rootGetters: any) => (page: number) => {
  const start = (page - 1) * 10;
  const stop = start + 10;
  return rootGetters['user/filteredUsersBySearchTerm']?.slice(start, stop);
};

const usersCount = (state: UserState, getters: any, rootState: RootState, rootGetters: any) => {
  return rootGetters['user/filteredUsersBySearchTerm']?.length;
};

const userRoles = (state: UserState, getters: any, rootState: RootState, rootGetters: any) => {
  const isAuthUserAnAdmin = rootGetters['user/isAuthUserAnAdmin'];
  if (isAuthUserAnAdmin) return state.userRoles;
  return state.userRoles.filter((item: UserRole) => item.id !== 'admin');
};

const participantRoles = (state: UserState) => {
  return state.userRoles.filter((item: UserRole) => ['musician', 'performer'].includes(item.id));
};

const getters: GetterTree<UserState, RootState> = {
  orderedUsers,
  users,
  usersExclDeleted,
  usersWithParticipantRoles,
  usersWithParticipantRolesExclDeleted,
  authUser,
  isAuthUserAnAdmin,
  userInfo,
  usersLoading,
  filteredUsersBySearchTerm,
  userById,
  usersSubset,
  usersCount,
  userRoles,
  participantRoles,
};

export default getters;
