<template>
  <v-expansion-panel v-if="event">
    <v-expansion-panel-title class="pl-0 pr-4 py-2">
      <v-list-item class="list-item">
        <v-list-item-title class="valign-center mb-2">
          <v-avatar start class="bg-grey-lighten-3 mr-2" size="24" v-if="event.eventType">{{
            eventTypeById(event.eventType).shortName
          }}</v-avatar>
          <span>{{ event.startTime }}</span> <span v-if="event.endTime">-</span>
          <span>{{ event.endTime }}</span>
        </v-list-item-title>
        <template v-if="address">
          <v-list-item-subtitle>{{ address.name }}</v-list-item-subtitle>
          <v-list-item-subtitle><AddressText :address="address" /></v-list-item-subtitle>
        </template>
      </v-list-item>
    </v-expansion-panel-title>
    <v-expansion-panel-text class="text-body-2">
      <AddressDetails :address="address" v-if="address" />

      <!-- eloadok -->
      <ParticipantList :participants="event.participants" />

      <v-list density="compact" class="pt-0">
        <v-list-item class="list-item pa-0" v-if="event.note">
          <span v-if="event.note">{{ event.note }}</span>
        </v-list-item>
      </v-list>

      <v-divider class="mt-4 my-4" v-if="isActionButtonGroup1Visible || isActionButtonGroup2Visible"></v-divider>

      <!-- gombok -->
      <v-row v-if="isActionButtonGroup1Visible">
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pa-0" v-if="!eventConfirmed">
          <v-btn color="blue-darken-2" variant="text" @click="confirmEvent()">{{ $t('accept') }}</v-btn>
        </v-col>
        <v-col cols="auto" class="py-0 text-green" v-if="eventConfirmed">{{ $t('accepted') }}</v-col>
      </v-row>

      <v-row v-if="isActionButtonGroup2Visible">
        <v-col cols="auto" class="pa-0">
          <v-btn color="red-darken-2" variant="text" @click="inGroup ? removeEventFromGroup() : deleteEvent()">{{
            inGroup ? $t('remove') : $t('delete')
          }}</v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pa-0">
          <v-btn color="blue-darken-2" variant="text" @click="copyEvent()">{{ $t('copy-event') }}</v-btn>
        </v-col>
        <v-col cols="auto" class="pa-0">
          <v-btn color="blue-darken-2" variant="text" @click="editEvent()">{{ $t('edit') }}</v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>
<script>
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { db } from '@/main';
import i18n from '@/i18n';
import { mdiAccountBoxOutline, mdiEmailOutline, mdiMapMarkerOutline, mdiNoteOutline, mdiPhoneOutline } from '@mdi/js';
import AddressText from '@/components/AddressText.vue';
import ParticipantList from './ParticipantList.vue';
import AddressDetails from '../AddressDetails.vue';

export default {
  name: 'EventListItem',
  props: ['event', 'inGroup'],
  components: {
    AddressText,
    ParticipantList,
    AddressDetails,
  },
  data() {
    return {
      icon: {
        contact: mdiAccountBoxOutline,
        email: mdiEmailOutline,
        mapMarker: mdiMapMarkerOutline,
        note: mdiNoteOutline,
        telephone: mdiPhoneOutline,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
    userCanEdit() {
      return this.user && ['admin', 'editor'].some(role => this.user?.userRoles?.includes(role));
    },
    address() {
      return this.event && this.event.addressId && this.$store.getters['address/addressById'](this.event.addressId);
    },
    eventConfirmed() {
      if (this.event && this.user) {
        const userId = this.user.uid;
        const participants = this.event.participants;
        if (participants.length == 0) {
          return false;
        }
        const participant = participants.find(participant => participant.userId == userId);
        if (!participant) {
          return false;
        }
        return participant.confirmed;
      } else {
        return false;
      }
    },
    isActionButtonGroup1Visible() {
      return this.user?.userRoles.some(role => ['performer', 'musician'].includes(role));
    },
    isActionButtonGroup2Visible() {
      return this.user?.userRoles.some(role => ['admin', 'editor'].includes(role));
    },
    eventTypes() {
      return this.$store.getters['eventType/eventTypesInclDeleted'];
    }
  },
  methods: {
    eventTypeById(id) {
      return this.eventTypes?.find(et => et.id === id) ?? '';
    },
    copyEvent() {
      this.$emit('copyEvent');
    },
    editEvent() {
      this.$emit('editEvent');
    },
    removeEventFromGroup() {
      this.$emit('removeEventFromGroup');
    },
    deleteEvent() {
      this.$emit('deleteEvent');
    },
    async confirmEvent() {
      if (this.event && this.user) {
        const { id, ...data } = JSON.parse(JSON.stringify(this.event));
        const userId = this.user.uid;
        data.participants.forEach(participant => {
          if (participant.userId == userId) {
            participant.confirmed = true;
          }
        });

        const collectionRef = collection(db, 'events');
        const docRef = doc(collectionRef, id);
        try {
          await setDoc(docRef, { ...data, updateTime: new Date().toISOString() });
          this.close();
        } catch (error) {
          this.$store.dispatch('setSnackbar', { text: i18n.t('command-unsuccessful-try-again') }, { root: true });
        }
      }
    },
  },
};
</script>
<style scoped>
.list-item {
  min-height: unset;
}
</style>
