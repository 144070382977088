import { Module } from 'vuex';
import { SchoolDistrictState } from './types';
import { RootState } from '@/store/types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const schoolDistrictStore: Module<SchoolDistrictState, RootState> = {
  namespaced: true,
  state: {
    schoolDistricts: [],
    schoolDistrictsLoading: false,
  },
  actions,
  mutations,
  getters,
};

export default schoolDistrictStore;
