<template>
  <div>
    <div v-if="props.participants?.length" class="mt-10 mb-4 block-title bg-grey-lighten-4">
      {{ $t('participants') }}
    </div>
    <v-card
      v-if="props.participants.length"
      elevation="1"
      :class="props.participants.length ? 'mb-6' : ''"
    >
      <v-list lines="two">
        <template v-for="(participant, index) in props.participants" :key="participant.userId">
          <ParticipantListItem :participant="participant" />

          <v-divider
            :key="index"
            v-if="props.participants.length && index < props.participants.length - 1"
          />
        </template>
      </v-list>
    </v-card>

    <div class="mt-4" v-if="props.participants.length > 1">
      <EmailLink :persons="props.participants" text="Email mindenkinek" />
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import ParticipantListItem from '@/components/list/ParticipantListItem.vue';
import EmailLink from '@/components/EmailLink.vue';

const props = defineProps(['participants']);
</script>
