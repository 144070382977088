<template>
  <v-autocomplete
    v-model="participantObj.userId"
    class="no-hint"
    variant="outlined"
    clearable
    :label="getAutocompleteLabel"
    :items="filteredUsers"
    :item-title="(item) => item.lastname + ' ' + item.firstname"
    item-value="id"
    @update:model-value="emitData()"
  />
  <v-switch
    v-model="participantObj.isDriver"
    class="no-hint"
    inset
    color="blue-darken-2"
    :disabled="!participantObj.userId"
    @update:model-value="emitData()"
  />
</template>

<script>
export default {
  name: 'ParticipantFormItem',
  props: ['role', 'instrument', 'participant'],
  emits: ['change'],
  data() {
    return {
      participantObj: {
        confirmed: null,
        isDriver: false,
        roleId: this.role?.id,
        instrumentId: this.instrument?.id,
        userId: null
      },
      userIdBefore: null,
    };
  },
  computed: {
    users() {
      return this.$store.getters['user/usersWithParticipantRoles'];
    },
    filteredUsers() {
      return this.getFilteredUsers(this.role?.id, this.instrument?.id);
    },
    getAutocompleteLabel() {
      return this.role?.id === 'performer' ? this.role?.name : this.instrument?.name ?? '';
    },
  },
  mounted() {
    if (this.participant) {
      this.participantObj = { ...this.participant };
      this.userIdBefore = this.participant.userId;
    }
  },
  methods: {
    getFilteredUsers(roleId, instrumentId) {
      const usersFilteredByRole = this.users?.filter(user => !user.deleted && user.userRoles?.includes(roleId)) ?? [];
      if (instrumentId) {
        return usersFilteredByRole.filter(user => user.instruments?.includes(instrumentId));
      } else {
        return usersFilteredByRole;
      }
    },
    emitData() {
      if (!this.participantObj.instrumentId) delete this.participantObj.instrumentId;
      if (!this.participantObj.userId) this.participantObj.isDriver = false;

      this.$emit('change', { participant: this.participantObj, userIdBefore: this.userIdBefore });

      this.userIdBefore = this.participantObj.userId;
    },
  },
};
</script>
<style lang="scss">
.no-hint {
  .v-text-field__details,
  .v-input__details {
    display: none;
  }
  .v-input__slot {
    margin-bottom: 0;
  }
}
</style>
