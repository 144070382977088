<template>
  <v-dialog v-model="isDialogVisible" :width="mobile ? '90vw' : 500" class="participant-info-dialog">
    <v-card :title="`${props.user.lastname} ${user.firstname}`">
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-title>Telefon</v-list-item-title>
            <v-list-item-subtitle>
              <a :href="`tel:${user.telephone}`" class="text-blue-darken-2">{{ user.telephone }}</a>
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item class="mt-3">
            <v-list-item-title>Email</v-list-item-title>
            <v-list-item-subtitle>
              <EmailLink :persons="[{ userId: props.user.id }]" :text="user.email" />
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card-text>
      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          text="Ok"
          @click="isDialogVisible = false"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { defineModel, PropType } from 'vue';
import { User } from 'types';
import { useDisplay } from 'vuetify';
import EmailLink from '@/components/EmailLink.vue';

const { mobile } = useDisplay();

const isDialogVisible = defineModel<boolean>({ required: true });

const props = defineProps({
  user: {
    type: Object as PropType<User>,
    required: true,
  },
});
</script>

<style lang="scss">
.participant-info-dialog .v-card-title {
  padding: 1.5rem 1rem 0 !important;
  white-space: unset;
  word-break: break-all;
}
</style>
