<template>
  <div>
    <v-app-bar flat color="blue-darken-3">
      <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
      <v-toolbar-title class="hidden-sm-and-down ml-0 px-4">{{ $t('places') }}</v-toolbar-title>
      <v-spacer />
      <v-text-field
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('search')"
        clearable
        v-model="searchTerm"
        @keyup="setSearchTerm()"
        @click:clear="clearSearchTerm()"
      />
      <v-spacer />
      <v-btn icon @click.stop="dialogVisible = true" v-if="userCanEdit">
        <v-icon icon="mdi-plus" />
      </v-btn>
    </v-app-bar>

    <v-container>
      <v-skeleton-loader type="list-item-two-line@10" v-if="loadingState" />
      <template v-else>
        <v-pagination
          v-if="totalPages > 1"
          class="my-6"
          v-model="page"
          :length="totalPages"
          :total-visible="10"
        />

        <div class="mb-4">Találatok száma: {{ addressCount }}</div>

        <v-expansion-panels v-if="addresses.length > 0" v-model="panel">
          <v-expansion-panel v-for="(item, i) in addresses" :key="i">
            <v-expansion-panel-title class="expansion-panel-header">
              <v-list-item class="list-item">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle><AddressText :address="item" /></v-list-item-subtitle>
              </v-list-item>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-list density="compact" class="pt-0">
                <v-list-item class="px-0" v-if="item.isSchool">
                  <v-list-item-content>{{ $t('school-district') }}: {{ item.schoolDistrict?.name ?? '' }}</v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0" v-if="item.city && item.address">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-map-marker-outline"></v-icon>
                  </template>
                  <GoogleMapsLink :address="item" />
                </v-list-item>
                <v-list-item class="px-0" v-if="item.contactName">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-account-box"></v-icon>
                  </template>
                  <span>{{ item.contactName }}</span>
                </v-list-item>
                <v-list-item class="pa-0" v-if="item.contactTelephone">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-phone"></v-icon>
                  </template>
                  <a :href="'tel:' + item.contactTelephone">{{ item.contactTelephone }}</a>
                </v-list-item>
                <v-list-item class="pa-0" v-if="item.contactEmail">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-email"></v-icon>
                  </template>
                  <a :href="'mailto:' + item.contactEmail">{{ item.contactEmail }}</a>
                </v-list-item>
                <v-list-item class="pa-0 mr-4" v-if="item.url">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-link-variant"></v-icon>
                  </template>
                  <a :href="item.url" target="_blank">{{ item.url }}</a>
                </v-list-item>
                <v-list-item class="pa-0" v-if="item.note">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-note-outline"></v-icon>
                  </template>
                  <span>{{ item.note }}<br /></span>
                </v-list-item>
              </v-list>

              <v-divider class="my-4" v-if="userCanEdit" />

              <v-row v-if="userCanEdit">
                <v-spacer />
                <v-col cols="auto" class="pa-0">
                  <v-btn color="blue-darken-2" variant="text" @click="edit(item.id)">{{ $t('edit') }}</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-list v-else>
          <v-list-item disabled>
            <v-list-item-title class="list-item-title">{{ $t('no-results') }}</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-pagination
          v-if="totalPages > 1"
          class="my-6"
          v-model="page"
          :length="totalPages"
          :total-visible="10"
        />
      </template>
    </v-container>

    <v-address-dialog
      v-if="dialogVisible"
      :visible="dialogVisible"
      :id="id"
      @close="closeDialog"
    ></v-address-dialog>
  </div>
</template>
<script>
import AddressDialog from '@/components/dialog/AdressDialog.vue';
import AddressText from '@/components/AddressText.vue';
import GoogleMapsLink from '@/components/GoogleMapsLink.vue';

export default {
  name: 'AddressesView',
  components: {
    'v-address-dialog': AddressDialog,
    AddressText,
    GoogleMapsLink,
  },
  data: () => ({
    dialogVisible: false,
    id: null,
    page: 1,
    panel: [],
    searchTerm: null,
  }),
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
    userCanEdit() {
      return this.user && ['admin', 'editor'].some(role => this.user?.userRoles?.includes(role));
    },
    addresses() {
      return this.$store.getters['address/addressesSubset'](this.page);
    },
    addressCount() {
      return this.$store.getters['address/addressCount'];
    },
    totalPages() {
      if (this.addressCount > 0) {
        return Math.ceil(this.addressCount / 10);
      } else {
        return 0;
      }
    },
    loadingState() {
      return this.$store.getters.loadingState;
    },
  },
  watch: {
    page(value) {
      if (value) {
        this.panel = [];
      }
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch('core/toggleDrawer', null);
    },
    edit(id) {
      this.id = id;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.id = null;
      this.dialogVisible = false;
    },
    setSearchTerm() {
      this.page = 1;
      this.$store.dispatch('address/setAddressSearchTerm', this.searchTerm, { root: true });
    },
    clearSearchTerm() {
      this.page = 1;
      this.$store.dispatch('address/setAddressSearchTerm', null, { root: true });
    },
  },
  beforeUnmount() {
    this.clearSearchTerm();
  },
};
</script>
<style scoped>
.confirm-text {
  font-size: 1rem;
}
.expansion-panel-header {
  padding: 6px 18px 6px 6px;
}
</style>
