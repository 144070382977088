import { DateTime } from 'luxon';

export const monthLabels = [
  'Január',
  'Február',
  'Március',
  'Április',
  'Május',
  'Június',
  'Július',
  'Augusztus',
  'Szeptember',
  'Október',
  'November',
  'December'
];

export const getDateNow = (): string => {
  const now = DateTime.now();
  return now.toISODate();
};

export const getDayName = (dateString: string): string => {
  const date = DateTime.fromFormat(dateString, 'yyyy-MM-dd').setLocale('hu');
  return date.toFormat('cccc');
};

export const addTime = (startTime: string, durationInMinutes: number): string => {
  const startTimeObj = DateTime.fromFormat(startTime, 'HH:mm');
  const endTimeObj = startTimeObj.plus({ minutes: durationInMinutes });
  return endTimeObj.toFormat('HH:mm');
};

export const isBetweenDates = (date: string, startDate: string, endDate: string): boolean => {
  const dateObj = DateTime.fromFormat(date, 'yyyy-MM-dd');
  const startObj = DateTime.fromFormat(startDate, 'yyyy-MM-dd');
  const endObj = DateTime.fromFormat(endDate, 'yyyy-MM-dd');
  return dateObj.toMillis() >= startObj.toMillis() && dateObj.toMillis() <= endObj.toMillis();
};

export const isBeforeOrSameDate = (date1: string, date2: string): boolean => {
  const date1Obj = DateTime.fromFormat(date1, 'yyyy-MM-dd');
  const date2Obj = DateTime.fromFormat(date2, 'yyyy-MM-dd');
  return date1Obj.toMillis() <= date2Obj.toMillis();
};

export const isAfterOrSameDate = (date1: string, date2: string): boolean => {
  const date1Obj = DateTime.fromFormat(date1, 'yyyy-MM-dd');
  const date2Obj = DateTime.fromFormat(date2, 'yyyy-MM-dd');
  return date1Obj.toMillis() >= date2Obj.toMillis();
};

export const formatDate = (date: string, format = 'yyyy.MM.dd'): string | null => {
  if (!date) return null;
  const dateTime = DateTime.fromFormat(date, 'yyyy-MM-dd');
  const formattedDate = dateTime.toFormat(format);
  return formattedDate;
};

export const formatJSDate = (date: Date, format = 'yyyy.MM.dd'): string | null => {
  if (!date) return null;
  const dateTime = DateTime.fromJSDate(date);
  const formattedDate = dateTime.toFormat(format);
  return formattedDate;
};

export const getDatesInRange = (startDate: string, endDate: string): string[] => {
  const dateArray = [];
  let currentDate = DateTime.fromFormat(startDate, 'yyyy-MM-dd');
  const stopDate = DateTime.fromFormat(endDate, 'yyyy-MM-dd');
  while (currentDate.toMillis() <= stopDate.toMillis()) {
    dateArray.push(currentDate.toFormat('yyyy-MM-dd'));
    currentDate = currentDate.plus({ days: 1 });
  }
  return dateArray;
};

export const addDay = (date: string, days: number): string | null => {
  const dateObj = DateTime.fromFormat(date, 'yyyy-MM-dd');
  const newDateObj = dateObj.plus({ days: days });
  return newDateObj.toISODate();
};

export const subtractDay = (date: string, days: number): string | null => {
  const dateObj = DateTime.fromFormat(date, 'yyyy-MM-dd');
  const newDateObj = dateObj.minus({ days: days });
  return newDateObj.toISODate();
};

export const addMonth = (date: string, months: number): string => {
  const dateObj = DateTime.fromFormat(date, 'yyyy-MM-dd');
  const newDateObj = dateObj.plus({ months: months });
  return newDateObj.toFormat('yyyy-MM-dd');
};

export const subtractMonth = (date: string, months: number): string => {
  const dateObj = DateTime.fromFormat(date, 'yyyy-MM-dd');
  const newDateObj = dateObj.minus({ months: months });
  return newDateObj.toFormat('yyyy-MM-dd');
};

export const isValidTime = (time: string): boolean => {
  const timeObj = DateTime.fromFormat(time, 'HH:mm');
  return timeObj.isValid;
};
