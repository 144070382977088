<template>
  <div>
    <v-app-bar flat color="blue-darken-3">
      <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
      <v-toolbar-title class="hidden-sm-and-down ml-0 px-4">{{ $t('stat') }}</v-toolbar-title>
      <v-spacer class="hidden-sm-and-down" />
      <v-text-field
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('search')"
        clearable
        v-model="searchTerm"
        class="hidden-sm-and-down"
        @click:clear="searchTerm = ''"
        v-if="user && user.role !== 'performer'"
      />
      <v-spacer />
      <v-toolbar-title class="mx-4">
        {{ formatDate(monthpicker, 'yyyy. ') }} {{ monthLabels[formatDate(monthpicker, 'M') - 1] }}
      </v-toolbar-title>
      <v-btn icon @click.stop="monthpicker = subtractMonth(monthpicker, 1)">
        <v-icon icon="mdi-arrow-left" />
      </v-btn>
      <v-btn icon @click.stop="monthpicker = addMonth(monthpicker, 1)">
        <v-icon icon="mdi-arrow-right" />
      </v-btn>
      <v-btn icon @click.stop="drawerRight = !drawerRight">
        <v-icon icon="mdi-calendar" />
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawerRight" location="right" width="300">
      <v-btn class="hidden-md-and-up ma-4 mr-2" icon @click.stop="drawerRight = !drawerRight">
        <v-icon icon="mdi-arrow-right" />
      </v-btn>
      <span class="hidden-md-and-up">{{ $t('close') }}</span>
      <v-skeleton-loader :loading="loadingState" type="image">
        <month-picker class="month-picker" v-model="monthpicker" ></month-picker>

        <v-divider v-if="user && user.role !== 'performer'"></v-divider>

        <v-list rounded v-if="user && user.role !== 'performer'">
          <v-list-subheader>{{ $t('download-data') }}</v-list-subheader>
          <v-list-item v-for="(item, i) in csvExportButtons" :key="i" v-on:click="download(i + 1)">
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-skeleton-loader>
    </v-navigation-drawer>

    <v-container>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader :loading="loadingState" type="table-tbody">
            <v-text-field
              class="hidden-md-and-up mt-4"
              v-if="user && user.role !== 'performer'"
              flat
              variant="solo-inverted"
              hide-details
              prepend-inner-icon="mdi-magnify"
              :label="$t('search')"
              clearable
              v-model="searchTerm"
              @click:clear="searchTerm = ''"
            />

            <v-data-table
              v-if="tableItems?.length"
              :headers="headers"
              :items="tableItems"
              :page="page"
              :items-per-page="itemsPerPage"
              :search="searchTerm"
            >

              <template v-slot:bottom>
                <div class="table-footer mt-5">
                  <v-spacer></v-spacer>
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                  />
                  <v-spacer></v-spacer>
                  <v-select
                    class="table-footer__ipp-selector"
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 200]"
                    variant="solo"
                  />
                </div>
              </template>
            </v-data-table>
            <v-alert
              text="Ebben a hónapban nem voltak előadások"
              v-else
            />
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { addMonth, formatDate, getDateNow, monthLabels, subtractMonth } from '../helpers/datetime';
import MonthPicker from '@/components/MonthPicker.vue';
export default {
  name: 'StatView',
  components: {
    'month-picker': MonthPicker,
  },
  data: () => ({
    drawerRight: null,
    page: 1,
    itemsPerPage: 25,
    headers: [
      { title: 'Név', value: 'userFullName', align: 'start', width: 35 },
      {
        title: 'Előadások (db)',
        value: 'numConfirmedEvents',
        align: 'end',
        width: 15,
      },
      {
        title: 'Előadások - nem elfogadott (db)',
        value: 'numUnconfirmedEvents',
        align: 'end',
        width: 15,
      },
      {
        title: 'Autóhasználat (km)',
        value: 'kms',
        align: 'end',
        width: 15,
      },
    ],
    searchTerm: '',
    monthpicker: getDateNow(),
    csvExportButtons: [
      { text: 'Előadások', icon: 'mdi-file-download-outline' },
      { text: 'Kimutatás', icon: 'mdi-file-download-outline' },
    ],
    monthLabels,
  }),
  computed: {
    loadingState() {
      return this.$store.getters.loadingState;
    },
    tableItems() {
      return this.$store.getters['core/statByMonth'](this.monthpicker.substr(0, 7));
    },
    user() {
      return this.$store.getters['user/authUser'];
    },
    eventsCsvData() {
      return this.$store.getters['core/eventsByMonthAsCSV'](this.monthpicker.substr(0, 7));
    },
    statCsvData() {
      return this.$store.getters['core/statByMonthAsCSV'](this.monthpicker.substr(0, 7));
    },
    pageCount () {
      return Math.ceil(this.tableItems.length / this.itemsPerPage);
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch('core/toggleDrawer', null);
    },
    addMonth(date, months) {
      return addMonth(date, months);
    },
    subtractMonth(date, months) {
      return subtractMonth(date, months);
    },
    formatDate(date, format) {
      return formatDate(date, format);
    },
    download(type) {
      const data = type === 1 ? this.eventsCsvData : this.statCsvData;
      const filename = 'bftz_' + (type === 1 ? 'eloadasok_' : 'stat_') + this.monthpicker.substr(0, 7) + '.csv';
      let csvContent = data.map(e => e.join(',')).join('\n');
      var link = document.createElement('a');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent));
      link.setAttribute('download', filename);
      document.body.appendChild(link); // required for FF
      link.click(); // this will download the file
    },
  },
  watch: {
    monthpicker() {
      // reset page to 1 if another month is selected
      this.page = 1;
    }
  },
};
</script>

<style lang="scss">
.table-footer {
  display: flex;
  align-items: center;

  &__ipp-selector {
    flex-grow: 0;
    max-width: 7rem;
  }

  .v-input__slot {
    margin: 0;
  }

  .v-text-field__details {
    display: none;
  }
}
</style>
