import { ActionContext, ActionTree } from 'vuex';
import { CoreState } from './types';
import { RootState } from '@/store/types';

const setLoading = (context: ActionContext<CoreState, RootState>, payload: any) => {
  context.commit('setLoading', payload);
};

const setInit = (context: ActionContext<CoreState, RootState>, payload: any) => {
  context.commit('setInit', payload);
};

const toggleDrawer = (context: ActionContext<CoreState, RootState>) => {
  context.commit('toggleDrawer');
};

const setSnackbar = (context: ActionContext<CoreState, RootState>, payload: any) => {
  context.commit('setSnackbar', payload);
};

const actions: ActionTree<CoreState, RootState> = {
  setLoading,
  setInit,
  toggleDrawer,
  setSnackbar,
};

export default actions;
