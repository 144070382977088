<template>
  <v-dialog persistent :model-value="visible" max-width="350">
    <v-form>
      <v-card v-if="!deleting">
        <v-card-title>{{ form.id ? $t('edit') : $t('add-new') }}</v-card-title>

        <v-text-field :label="$t('name')" required variant="underlined" color="blue-darken-2" v-model="form.data.name" class="ma-4" />

        <v-card-actions>
          <v-btn
              color="red"
              text
              v-if="form.id"
              @click="deleting = true"
          >{{ $t('delete') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="$emit('close')">{{ $t('cancel') }}</v-btn>
          <v-btn color="blue-darken-2" variant="text" @click="form.id ? update() : add()">{{ $t('save') }}</v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="deleting">
        <v-card-title>{{ $t('delete') }}</v-card-title>
        <v-card-text class="confirm-text">{{ $t('confirm-to-delete') }}</v-card-text>
        <v-card-actions>
          <v-btn color="red-darken-2" variant="text" @click="remove()">{{ $t('delete') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-2" variant="text" @click="deleting = false">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { addDoc, collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '@/main';

export default {
  name: 'SchoolDistrictDialog',
  data: () => ({
    loading: false,
    deleting: false,
    form: {
      id: null,
      data: {
        name: null,
        assignedAddresses: [],
        deleted: false,
      },
    },
  }),
  props: ['visible', 'id'],
  emits: ['close'],
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
    userCanEdit() {
      return this.user && ['admin', 'editor'].some(role => this.user?.userRoles?.includes(role));
    },
    schoolDistrict() {
      return this.id && this.$store.getters['schoolDistrict/schoolDistrictById'](this.id);
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch('core/toggleDrawer', null);
    },
    showSnackbar(text) {
      this.$store.dispatch('core/setSnackbar', { text });
    },
    async add() {
      const collectionRef = collection(db, 'schoolDistricts');
      try {
        await addDoc(collectionRef, {
          ...this.form.data,
          createTime: new Date().toISOString(),
          updateTime: new Date().toISOString()
        });
        this.$emit('close');
      } catch (error) {
        this.showSnackbar(error);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      const collectionRef = collection(db, 'schoolDistricts');
      const docRef = doc(collectionRef, this.form.id);
      try {
        await setDoc(docRef, {
          ...this.form.data,
          updateTime: new Date().toISOString(),
        });
        this.$emit('close');
      } catch (error) {
        this.showSnackbar(error);
      } finally {
        this.loading = false;
      }
    },
    async remove() {
      const collectionRef = collection(db, 'schoolDistricts');
      const docRef = doc(collectionRef, this.form.id);
      try {
        await updateDoc(docRef, {
          deleted: true,
          updateTime: new Date().toISOString(),
        });
        this.$emit('close');
      } catch (error) {
        this.showSnackbar(error);
      }
    },
  },
  created() {
    if (this.id) {
      const { id, ...data } = this.schoolDistrict;
      this.form.id = id;
      this.form.data = JSON.parse(JSON.stringify(data));
    }
  },
};
</script>

<style scoped>
.confirm-text {
  padding: 1rem 1rem 0.625rem !important;
}
</style>
